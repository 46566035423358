import React from "react";
import TaroMilk from "../../assets/MelonaMilkNutritionImages/TaroMilkIcon.png";

function UbeMilkNutritionalInfo() {
  return (
    <div className="flex flex-col items-center justify-center pb-20 mb-10 px-4 sm:px-8 -mt-10 md:px-16 z-70">
      {/* Set a maximum width for the component */}
      <div className="w-full max-w-screen-xl mx-auto rounded-lg">
        <div className="flex flex-col md:flex-row items-center justify-center">
          {/* VanillaMilk Image */}
          <div className="">
            <img
              src={TaroMilk}
              alt="Food Product"
              className="h-auto w-64 ml-6 md:mr-52 -ml-10 lg:-ml-0"
            />
          </div>
          {/* Description */}
          <div className="">
            <h2 className="text-xl font-semibold text-black ">DESCRIPTION</h2>
            <div className="h-1  bg-purple-300 my-2 "></div>
            <p className="text-black text-lg mb-4">
              Unique in colour and taste, the enchanting allure of our Ube
              Flavoured Milk is a tribute to the iconic Purple Yam. Dive into a
              world of creamy nuttiness, as the essence of Ube dances with the
              silky richness of milk. Each sip is a celebration of the
              Philippines' culinary heritage, offering a taste that's both
              unique and indulgent.
            </p>
          </div>
        </div>
        {/* Ingredients */}
        <div className="mb-4">
          <h2 className="text-xl font-semibold text-black">INGREDIENTS</h2>
          <div className="h-1 bg-purple-300 my-2"></div>
          <p className="text-black text-lg mb-10">
            Milk, Water, Sugar, Dextrin, Artificial Flavors, Taro juice
            concentrate, Emulsifier (Mono-and Diglycerides, Carrageenan, Guar
            Gum), Colours (allura red, brilliant blue FCF)
            <br></br>
            <br></br>
            <span className="font-bold">Contains:</span> Milk
          </p>
        </div>
        {/* Nutritional Information */}
        <div className="mb-4 md:hidden">
          <h2 className="text-xl font-semibold text-black">NUTRITIONAL</h2>
          <div className="h-1 bg-purple-300 my-2"></div>
          <div className="grid grid-cols-2 gap-1">
            <div className="font-semibold text-black">Serving Size</div>
            <div className="text-right text-black">1 Carton (200ml)</div>
            <div className="font-semibold text-black">Calories</div>
            <div className="text-right text-black">130</div>
            <div className="font-semibold text-black">Total Fat</div>
            <div className="text-right text-black">5 g</div>
            <div className="font-semibold text-black">Saturated Fat</div>
            <div className="text-right text-black">3.5 g</div>
            <div className="font-semibold text-black">Trans Fat</div>
            <div className="text-right text-black">0 g</div>
            <div className="font-semibold text-black">Cholesterol</div>
            <div className="text-right text-black">15 mg</div>
            <div className="font-semibold text-black">Sodium</div>
            <div className="text-right text-black">60 mg</div>
            <div className="font-semibold text-black">Total Carbohydrate</div>
            <div className="text-right text-black">25 g</div>
            <div className="font-semibold text-black">Sugars</div>
            <div className="text-right text-black">20 g</div>
            <div className="font-semibold text-black">Protein</div>
            <div className="text-right text-black">4 g</div>
            <div className="font-semibold text-black">Vitamin D</div>
            <div className="text-right text-black">0 g</div>
            <div className="font-semibold text-black">Potassium</div>
            <div className="text-right text-black">150 mg</div>
            <div className="font-semibold text-black">Calcium</div>
            <div className="text-right text-black">125 mg</div>
            <p className="font-semibold text-black">Iron</p>
              <p className="text-right text-black">0 mg</p>
          </div>
        </div>

        <div className="hidden md:block">
          <h2 className="text-xl font-semibold text-black">NUTRITIONAL</h2>
          <div className="h-1 bg-purple-300 my-2"></div>
          <div className="grid grid-cols-4 gap-1">
            <div>
              <div className="font-semibold text-black">Serving Size</div>
              <div className="font-semibold text-black">Calories</div>
              <div className="font-semibold text-black">Total Fat</div>
              <div className="font-semibold text-black">Saturated Fat</div>
              <div className="font-semibold text-black">Trans Fat</div>
              <div className="font-semibold text-black">Cholesterol</div>
              <div className="font-semibold text-black">Sodium</div>
            </div>
            <div className="md:mr-5 lg:mr-20">
              <div className="text-right text-black">1 Carton (200ml)</div>
              <div className="text-right text-black">130</div>
              <div className="text-right text-black">5 g</div>
              <div className="text-right text-black">3.5 g</div>
              <div className="text-right text-black">0 g</div>
              <div className="text-right text-black">15 mg</div>
              <div className="text-right text-black">60 mg</div>
            </div>
            <div className="md:ml-5 lg:mr-20">
              <div className="font-semibold text-black">Total Carbohydrate</div>
              <div className="font-semibold text-black">Sugars</div>
              <div className="font-semibold text-black">Protein</div>
              <div className="font-semibold text-black">Vitamin D</div>
              <div className="font-semibold text-black">Potassium</div>
              <div className="font-semibold text-black">Calcium</div>
              <div className="font-semibold text-black">Iron</div>
            </div>
            <div>
              <div className="text-right text-black">25 g</div>
              <div className="text-right text-black">20 g</div>
              <div className="text-right text-black">4 g</div>
              <div className="text-right text-black">0 g</div>
              <div className="text-right text-black">150 mg</div>
              <div className="text-right text-black">125 mg</div>
              <div className="text-right text-black">0 mg</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default UbeMilkNutritionalInfo;
