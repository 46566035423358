import React from "react";
import VanillaMilk from "../../assets/MelonaMilkNutritionImages/VanillaMilkIcon.png";

function VanillaMilkNutritionalInfo() {
  return (
    <div className="flex flex-col items-center justify-center pb-20 mb-10 px-4 sm:px-8 -mt-10 md:px-16 z-70">
      {/* Set a maximum width for the component */}
      <div className="w-full max-w-screen-xl mx-auto rounded-lg">
        <div className="flex flex-col md:flex-row items-center justify-center">
          {/* VanillaMilk Image */}
          <div className="">
            <img
              src={VanillaMilk}
              alt="Food Product"
              className="h-auto w-64 ml-6 md:mr-52 -ml-10 lg:-ml-0"
            />
          </div>
          {/* Description */}
          <div className="">
            <h2 className="text-xl font-semibold text-black">DESCRIPTION</h2>
            <div className="h-1 bg-cyan-300 my-2"></div>
            <p className="text-black text-lg mb-4 ">
              Introducing an all-time classic is our Vanilla Flavoured Milk.
              Each sip is a smooth journey where the classic essence of vanilla
              swirls harmoniously with the creaminess of fresh milk. Elevate
              your moments with the comforting familiarity of vanilla,
              transforming every sip into a pure, soothing delight.
            </p>
          </div>
        </div>
        {/* Ingredients */}
        <div className="mb-4">
          <h2 className="text-xl font-semibold text-black">INGREDIENTS</h2>
          <div className="h-1 bg-cyan-300 my-2"></div>
          <p className="text-black text-lg mb-10">
            Milk, Water, Sugar, Skim Milk Powder, Whey Powder, Artificial
            Flavor, Emulsifier (Mono-and Diglycerides), Vanilla Extract,
            Stabilizers (Carrageenan, Guar Gum)
            <br></br>
            <br></br>
            <span className="font-bold">Contains:</span> Milk
          </p>
        </div>
        {/* Nutritional Information */}
        <div className="mb-4 md:hidden">
          <h2 className="text-xl font-semibold text-black">NUTRITIONAL</h2>
          <div className="h-1 bg-cyan-300 my-2"></div>
          <div className="grid grid-cols-2 gap-1">
            <div className="font-semibold text-black">Serving Size</div>
            <div className="text-right text-black">1 Carton (200ml)</div>
            <div className="font-semibold text-black">Calories</div>
            <div className="text-right text-black">130</div>
            <div className="font-semibold text-black">Total Fat</div>
            <div className="text-right text-black">4 g</div>
            <div className="font-semibold text-black">Saturated Fat</div>
            <div className="text-right text-black">2.5 g</div>
            <div className="font-semibold text-black">Trans Fat</div>
            <div className="text-right text-black">0 g</div>
            <div className="font-semibold text-black">Cholesterol</div>
            <div className="text-right text-black">10 mg</div>
            <div className="font-semibold text-black">Sodium</div>
            <div className="text-right text-black">70 mg</div>
            <div className="font-semibold text-black">Total Carbohydrate</div>
            <div className="text-right text-black">19 g</div>
            <div className="font-semibold text-black">Sugars</div>
            <div className="text-right text-black">19 g</div>
            <div className="font-semibold text-black">Protein</div>
            <div className="text-right text-black">5 g</div>
            <div className="font-semibold text-black">Vitamin D</div>
            <div className="text-right text-black">0 g</div>
            <div className="font-semibold text-black">Potassium</div>
            <div className="text-right text-black">300 mg</div>
            <div className="font-semibold text-black">Calcium</div>
            <div className="text-right text-black">200 mg</div>
            <p className="font-semibold text-black">Iron</p>
              <p className="text-right text-black">0 mg</p>
          </div>
        </div>

        <div className="hidden md:block">
          <h2 className="text-xl font-semibold text-black">NUTRITIONAL</h2>
          <div className="h-1 bg-cyan-300 my-2"></div>
          <div className="grid grid-cols-4 gap-1">
            <div>
              <div className="font-semibold text-black">Serving Size</div>
              <div className="font-semibold text-black">Calories</div>
              <div className="font-semibold text-black">Total Fat</div>
              <div className="font-semibold text-black">Saturated Fat</div>
              <div className="font-semibold text-black">Trans Fat</div>
              <div className="font-semibold text-black">Cholesterol</div>
              <div className="font-semibold text-black">Sodium</div>
            </div>
            <div className="md:mr-5 lg:mr-20">
              <div className="text-right text-black">1 Carton (200ml)</div>
              <div className="text-right text-black">130</div>
              <div className="text-right text-black">4 g</div>
              <div className="text-right text-black">2.5 g</div>
              <div className="text-right text-black">0 g</div>
              <div className="text-right text-black">10 mg</div>
              <div className="text-right text-black">70 mg</div>
            </div>
            <div className="md:ml-5 lg:mr-20">
              <div className="font-semibold text-black">Total Carbohydrate</div>
              <div className="font-semibold text-black">Sugars</div>
              <div className="font-semibold text-black">Protein</div>
              <div className="font-semibold text-black">Vitamin D</div>
              <div className="font-semibold text-black">Potassium</div>
              <div className="font-semibold text-black">Calcium</div>
              <div className="font-semibold text-black">Iron</div>
            </div>
            <div>
              <div className="text-right text-black">19 g</div>
              <div className="text-right text-black">19 g</div>
              <div className="text-right text-black">5 g</div>
              <div className="text-right text-black">0 g</div>
              <div className="text-right text-black">300 mg</div>
              <div className="text-right text-black">200 mg</div>
              <div className="text-right text-black">0 mg</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default VanillaMilkNutritionalInfo;
