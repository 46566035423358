import React from "react";
import MelonaBars from "../assets/ProductsComponent/ProductBars.png";
import Samanco from "../assets/ProductsComponent/ProductSamanco.png";
import Milk from "../assets/ProductsComponent/ProductsMilk.jpg";
import ProductComponent from "./ProductComponent";
import { LazyLoadComponent } from "react-lazy-load-image-component";

function Products() {
  const productsData = [
    { imageSrc: MelonaBars, link: "/MelonaBarsProducts", title: "MELONA" },
    { imageSrc: Samanco, link: "/Samanco", title: "SAMANCO" },
    { imageSrc: Milk, link: "/MelonaMilk", title: "FLAVOURED MILK " },
  ];

  return (
    <LazyLoadComponent>
      <div className="py-3 text-center md:py-10">
        <h1 className="mt-5 mb-5 text-2xl font-bold md:text-5xl ">
          OUR PRODUCTS
        </h1>
      </div>
      <div className="bg-white z-100 xl:scale-110">
        <div className="container px-4 py-12 mx-auto sm:px-6 lg:px-8">
          <div className="grid grid-cols-1 gap-2 -z-4 md:grid-cols-2 lg:grid-cols-3">
            {productsData.map((product, index) => (
              <ProductComponent
                key={index}
                imageSrc={product.imageSrc}
                link={product.link}
                title={product.title}
              />
            ))}
          </div>
        </div>
      </div>
    </LazyLoadComponent>
  );
}

export default Products;