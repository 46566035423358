import React, { useState } from "react";
import BananaMilk from "../assets/MelonaMilkProductPage/BananaMilk.png";
import CoffeeMilk from "../assets/MelonaMilkProductPage/CoffeeMilk.png";
import MelonMilk from "../assets/MelonaMilkProductPage/MelonMilk.png";
import StrawberryMilk from "../assets/MelonaMilkProductPage/StrawberryMilk.png";
import VanillaMilk from "../assets/MelonaMilkProductPage/VanillaMilk.png";
import UbeMilk from "../assets/MelonaMilkProductPage/UbeMilk.png";
import TaroMilkIcon from "../assets/MelonaMilkIcons/TaroMilkIcon.png";
import CoffeeMilkIcon from "../assets/MelonaMilkIcons/CoffeeMilkIcon.png";
import VanillaMilkIcon from "../assets/MelonaMilkIcons/VanillaMilkIcon.png";
import BananaMilkIcon from "../assets/MelonaMilkIcons/BananaMilkIcon.png";
import MelonMilkIcon from "../assets/MelonaMilkIcons/MelonMilkIcon.png";
import StrawberryMilkIcon from "../assets/MelonaMilkIcons/StrawberryMilkIcon.png";
import MelonMilkNutritionalInfo from "../components/nutritionalInfoMelonaMilk/MelonMilkNutritionalInfo";
import BananaMilkNutritionalInfo from "../components/nutritionalInfoMelonaMilk/BananaMilkNutritionalInfo";
import StrawberryMilkNutritionalInfo from "../components/nutritionalInfoMelonaMilk/StrawberryMilkNutritionalInfo";
import VanillaMilkNutritionalInfo from "../components/nutritionalInfoMelonaMilk/VanillaMilkNutritionalInfo";
import CoffeeMilkNutritionalInfo from "../components/nutritionalInfoMelonaMilk/CoffeeMilkNutritionalInfo";
import UbeMilkNutritionalInfo from "../components/nutritionalInfoMelonaMilk/UbeMilkNutritionalInfo";
import {
  LazyLoadComponent,
  LazyLoadImage,
} from "react-lazy-load-image-component";

function MelonaMilkProductPage() {
  const melonaMilkFlavors = [
    {
      image: MelonMilk,
      icon: MelonMilkIcon,
      nutrition: <MelonMilkNutritionalInfo />,
      backgroundColour: "#dcfce7", // Add background color for Melon Milk flavor
    },
    {
      image: BananaMilk,
      icon: BananaMilkIcon,
      nutrition: <BananaMilkNutritionalInfo />,
      backgroundColour: "#fef3c7", // Add background color for Banana Milk flavor
    },
    {
      image: StrawberryMilk,
      icon: StrawberryMilkIcon,
      nutrition: <StrawberryMilkNutritionalInfo />,
      backgroundColour: "#fee2e2", // Add background color for Strawberry Milk flavor
    },
    {
      image: VanillaMilk,
      icon: VanillaMilkIcon,
      nutrition: <VanillaMilkNutritionalInfo />,
      backgroundColour: "", // Add background color for Vanilla Milk flavor
    },
    {
      image: CoffeeMilk,
      icon: CoffeeMilkIcon,
      nutrition: <CoffeeMilkNutritionalInfo />,
      backgroundColour: "#ffedd5", // Add background color for Coffee Milk flavor
    },
    {
      image: UbeMilk,
      icon: TaroMilkIcon,
      nutrition: <UbeMilkNutritionalInfo />,
      backgroundColour: "#f3e8ff", // Add background color for Lychee Peach Milk flavor
    },
  ];

  const [currentFlavorIndex, setCurrentFlavorIndex] = useState(0);

  const currentFlavor = melonaMilkFlavors[currentFlavorIndex];

  const firstRowIconsMobile = [
    MelonMilkIcon,
    BananaMilkIcon,
    StrawberryMilkIcon,
  ];

  const firstRowIconsDesktop = [
    MelonMilkIcon,
    BananaMilkIcon,
    StrawberryMilkIcon,
    VanillaMilkIcon,
    CoffeeMilkIcon,
    TaroMilkIcon,
  ];

  const secondRowIcons = [VanillaMilkIcon, CoffeeMilkIcon, TaroMilkIcon];

  return (
    <React.Fragment>
      <div
        className="pt-20"
        style={{ backgroundColor: currentFlavor.backgroundColour }}
      >
        <div className="z-3 relative">
          <LazyLoadImage
            src={currentFlavor.image}
            alt="Melona Product"
            className="w-full object-cover rounded-lg md:rounded-xl lg:h-[110vh]" // Adjust the width as needed
          />

          <div className="flex justify-center mt-4">
            {/* Display the mobile icons only on mobile screens */}
            <div className="md:hidden">
              {firstRowIconsMobile.map((icon, index) => (
                <button
                  key={index}
                  className={`mx-2 ${
                    currentFlavorIndex === index
                      ? "transform scale-110"
                      : "transition-transform hover:scale-110"
                  }`}
                  onClick={() => setCurrentFlavorIndex(index)}
                >
                  <LazyLoadImage
                    src={icon}
                    alt={`Melona Flavor Icon ${index}`}
                    className="w-20 h-20 animate-bounce"
                  />
                </button>
              ))}
            </div>

            {/* Display all icons for desktop and iPad screens */}
            <div className="hidden md:flex">
              {firstRowIconsDesktop.map((icon, index) => (
                <button
                  key={index}
                  className={`mx-2 ${
                    currentFlavorIndex === index
                      ? "transform scale-110"
                      : "transition-transform hover:scale-110"
                  }`}
                  onClick={() => setCurrentFlavorIndex(index)}
                >
                  <LazyLoadImage
                    src={icon}
                    alt={`Melona Flavor Icon ${index}`}
                    className="w-20 h-20 animate-bounce"
                  />
                </button>
              ))}
            </div>
          </div>

          <div className="flex justify-center mt-4 md:hidden">
            {secondRowIcons.map((icon, index) => (
              <button
                key={index}
                className={`mx-2  ${
                  currentFlavorIndex === index + firstRowIconsMobile.length
                    ? "transform scale-110"
                    : "transition-transform hover:scale-110"
                }`}
                onClick={() =>
                  setCurrentFlavorIndex(index + firstRowIconsMobile.length)
                }
              >
                <LazyLoadImage
                  src={icon}
                  alt={`Melona Flavor Icon ${index}`}
                  className="w-20 h-20 animate-bounce"
                />
              </button>
            ))}
          </div>
        </div>

        <LazyLoadComponent>
          <div className="z-3">{currentFlavor.nutrition}</div>
        </LazyLoadComponent>
      </div>
    </React.Fragment>
  );
}

export default MelonaMilkProductPage;
