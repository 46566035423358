import React, { useState, useEffect } from "react";
import {
  LazyLoadComponent,
  LazyLoadImage,
} from "react-lazy-load-image-component";

const ImageSlider = ({ images, intervalTime = 3000 }) => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const timer = setInterval(() => {
      const newIndex = (currentImageIndex + 1) % images.length;
      setCurrentImageIndex(newIndex);
    }, intervalTime);

    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      clearInterval(timer);
      window.removeEventListener("resize", handleResize);
    };
  }, [currentImageIndex, intervalTime, images.length]); // Add images.length here

  const getImageForScreenWidth = () => {
    if (windowWidth < 768) {
      return images[currentImageIndex];
    } else {
      const largeScreenWidthIndex = (currentImageIndex + 1) % images.length;
      return images[largeScreenWidthIndex];
    }
  };

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <LazyLoadComponent>
      <div className="-z-1">
        <div className="w-full">
          <LazyLoadImage
            src={getImageForScreenWidth()}
            alt="Slider Banner"
            style={{ objectPosition: "center top" }}
            className="object-cover max-h-[80vh] w-full"
          />
        </div>
      </div>
    </LazyLoadComponent>
  );
};

export default ImageSlider;
