import React, { useState } from "react";
import BBbigRedBean from "../assets/BBbigProductPage/BbbigRedBean.jpg";
import BBbigAMatcha from "../assets/BBbigProductPage/BbbigMatcha.jpg";
import RedBeanIcon from "../assets/SamancoIcons/RedBeanSamancoIcon.png";
import GreenTeaIcon from "../assets/SamancoIcons/GreenTeaSamancoIcon.png";
import RedBeanBBbigNutritionalInfo from "../components/nutritionalInfoBBbig/RedBeanBBbigNutritionalInfo";
import GreenTeaBBbigNutritionalInfo from "../components/nutritionalInfoBBbig/GreenTeaBBbigNutritionalInfo";
import {
  LazyLoadComponent,
  LazyLoadImage,
} from "react-lazy-load-image-component";

function BBbigProductPage() {
  const BbbigFlavours = [
    {
      image: BBbigRedBean,
      icon: RedBeanIcon,
      nutrition: <RedBeanBBbigNutritionalInfo />,
      backgroundColour: "#fecaca", // Add background color for Red Bean flavor
    },
    {
      image: BBbigAMatcha,
      icon: GreenTeaIcon,
      nutrition: <GreenTeaBBbigNutritionalInfo />,
      backgroundColour: "#dcfce7", // Add background color for Green Tea flavor
    },
  ];

  const [currentFlavorIndex, setCurrentFlavorIndex] = useState(0);

  const currentFlavor = BbbigFlavours[currentFlavorIndex];

  const firstRowIcons = [RedBeanIcon, GreenTeaIcon];

  return (
    <React.Fragment>
      <div style={{ backgroundColor: currentFlavor.backgroundColour }}>
        <div className="pt-20">
          <div className="z-3">
            <LazyLoadImage
              src={currentFlavor.image}
              alt="BBbig Product"
              className="w-full object-cover rounded-lg md:rounded-xl lg:h-[110vh]"
            />

            <div className="flex justify-center mt-4">
              {firstRowIcons.map((icon, index) => (
                <button
                  key={index}
                  className={`mx-2 ${
                    currentFlavorIndex === index
                      ? "transform scale-110"
                      : "transition-transform hover:scale-110"
                  }`}
                  onClick={() => setCurrentFlavorIndex(index)}
                >
                  <LazyLoadImage
                    src={icon}
                    alt={`BBbig Flavor Icon ${index}`}
                    className="w-20 h-20 animate-bounce"
                  />
                </button>
              ))}
            </div>
          </div>
        </div>
        <LazyLoadComponent>
          <div className="mb-8 md:mb-16 lg:mb-20 -z-4">
            {currentFlavor.nutrition}
          </div>
        </LazyLoadComponent>
      </div>
    </React.Fragment>
  );
}

export default BBbigProductPage;
