import React from "react";
import MelonMelona from "../../assets/nutritionalinfo/melonMelona/melon.png";

const MelonNutritionalInformation = () => {
  return (
    <div className="flex flex-col items-center justify-center mb-10 px-4 sm:px-8 md:px-16 z-3">
      <div className="w-full max-w-screen-xl rounded-lg p-6 lg:p-8">
        <div className="flex flex-col md:flex-row items-center justify-center">
          <img
            src={MelonMelona}
            alt="Food Product"
            className="w-24 ml-10 md:w-40 mr-10"
          />
          <div className="mt-4 md:mt-0 ml-0 md:ml-4">
            {" "}
            {/* Adjusted margin */}
            <div className="mb-4">
              <h2 className="text-xl font-semibold text-black">DESCRIPTION</h2>
              <div className="h-1 bg-lime-400 my-2"></div>
              <p className="text-black text-m">
                Journey back to 1992 with our original Melon Bar, the very heart
                and soul of Melona. Feel the authentic burst of real melon taste
                in every bite—a timeless classic that's rich, creamy, and fresh.
                Our Melon Bar captures the essence of the season's juiciest
                melons and preserves them in a delicious frozen form.
              </p>
            </div>
            <div className="mb-4">
              <h2 className="text-xl font-semibold text-black">INGREDIENTS</h2>
              <div className="h-1 bg-lime-400 my-2"></div>
              <p className="text-black text-m">
                Water, Sugars (sugar, com syrup, melon syrup), Milk ingredient,
                Modified milk ingredients, Liquid yolk mix, Natural flavour,
                Citrus fiber, Guar gum, Locust bean gum, Salt, Lemon juice
                concentrate, Xanthan Gum, Carrageenan, Colours (brilliant blue
                FCF, turmeric)
                <br></br>
                <br></br>
                <span className="font-bold">Contains:</span> Milk
              </p>
            </div>
            <div className="mb-4 md:hidden">
              <h2 className="text-xl font-semibold text-black">NUTRITIONAL</h2>
              <div className="h-1 bg-lime-400 my-2"></div>
              <div className="grid grid-cols-2 gap-2">
                <p className="font-semibold text-black">Serving Size</p>
                <p className="text-right text-black">1 bar (80ml)</p>
                <p className="font-semibold text-black">Calories</p>
                <p className="text-right text-black">130</p>
                <p className="font-semibold text-black">Total Fat</p>
                <p className="text-right text-black">5 g</p>
                <p className="font-semibold text-black">Saturated Fat</p>
                <p className="text-right text-black">3.5 g</p>
                <p className="font-semibold text-black">Trans Fat</p>
                <p className="text-right text-black">0 g</p>
                <p className="font-semibold text-black">Cholesterol</p>
                <p className="text-right text-black">25 mg</p>
                <p className="font-semibold text-black">Sodium</p>
                <p className="text-right text-black">45 mg</p>
                <p className="font-semibold text-black">Total Carbohydrate</p>
                <p className="text-right text-black">21 g</p>
                <p className="font-semibold text-black">Sugars</p>
                <p className="text-right text-black">18 g</p>
                <p className="font-semibold text-black">Fibre</p>
                <p className="text-right text-black">0 g</p>
                <p className="font-semibold text-black">Protein</p>
                <p className="text-right text-black">1 g</p>
                <p className="font-semibold text-black">Potassium</p>
                <p className="text-right text-black">75 mg</p>
                <p className="font-semibold text-black">Calcium</p>
                <p className="text-right text-black">40 mg</p>
                <p className="font-semibold text-black">Iron</p>
                <p className="text-right text-black">0.4 mg</p>
              </div>
            </div>
            <div className="mb-4 hidden md:block">
              <h2 className="text-xl font-semibold text-black">NUTRITIONAL</h2>
              <div className="h-1 bg-lime-400 my-2"></div>
              <div className="grid grid-cols-4 gap-1">
                <div>
                  <div className="font-semibold text-black">Serving Size</div>
                  <div className="font-semibold text-black">Calories</div>
                  <div className="font-semibold text-black">Total Fat</div>
                  <div className="font-semibold text-black">Saturated Fat</div>
                  <div className="font-semibold text-black">Trans Fat</div>
                  <div className="font-semibold text-black">
                    Total Carbohydrate
                  </div>
                  <div className="font-semibold text-black">Sugars</div>
                </div>
                <div className="mr-2 lg:mr-10">
                  <div className="text-right text-black">1 bar (80ml)</div>
                  <div className="text-right text-black">130</div>
                  <div className="text-right text-black">5 g</div>
                  <div className="text-right text-black">3.5 g</div>
                  <div className="text-right text-black">0 g</div>
                  <div className="text-right text-black">21 g</div>
                  <div className="text-right text-black">18 g</div>
                </div>
                <div className="ml-2 lg:ml-10">
                  <div className="font-semibold text-black">Fibre</div>
                  <div className="font-semibold text-black">Cholesterol</div>
                  <div className="font-semibold text-black">Protein</div>
                  <div className="font-semibold text-black">Sodium</div>
                  <div className="font-semibold text-black">Potassium</div>
                  <div className="font-semibold text-black">Calcium</div>
                  <div className="font-semibold text-black">Iron</div>
                </div>
                <div>
                  <div className="text-right text-black">0 g</div>
                  <div className="text-right text-black">25 mg</div>
                  <div className="text-right text-black">1 g</div>
                  <div className="text-right text-black">45 mg</div>
                  <div className="text-right text-black">75 mg</div>
                  <div className="text-right text-black">40 mg</div>
                  <div className="text-right text-black">0.4 mg</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MelonNutritionalInformation;
