import React from "react";
import CoconutMelona from "../../assets/nutritionalinfo/coconutMelona/coconut.png";

const CoconutMelonaNutrition = () => {
  return (
    <div className="flex flex-col items-center justify-center mb-10 px-4 sm:px-8 md:px-16 z-3">
      <div className="w-full max-w-screen-xl rounded-lg p-6 lg:p-8">
        <div className="flex flex-col md:flex-row items-center justify-center">
          <img
            src={CoconutMelona}
            alt="Food Product"
            className="w-24 ml-10 md:w-40 mr-10"
          />
          <div className="mt-4 md:mt-0">
            <div className="mb-4">
              <h2 className="text-xl font-semibold text-black">DESCRIPTION</h2>
              <div className="h-1 bg-blue-400 my-2"></div>
              <p className="text-black text-m">
                Set sail for a tropical paradise with our Coconut Bar, a
                tantalizing voyage with every bite. Immerse yourself in the
                indulgent, fragrant fusion of fresh skim milk and tender coconut
                cream. With its silky embrace, coconut invites you lean back,
                unwind, and enjoy the cool taste on a distant shore.
              </p>
            </div>
            <div className="mb-4">
              <h2 className="text-xl font-semibold text-black">INGREDIENTS</h2>
              <div className="h-1 bg-blue-400 my-2"></div>
              <p className="text-black text-m">
                Water, Sugars (corn syrup, sugar), Coconut milk (coconut
                extract, water, xanthan gum, guar gum, carrageenan), Modified
                milk ingredients (skim milk powder, whey powder), Citrus fiber,
                Natural flavor, Guar gum, Locust bean gum, Salt
                <br></br>
                <br></br>
                <span className="font-bold">Contains:</span> Milk and Tree Nuts
                (coconut)
              </p>
            </div>
            <div className="mb-4 md:hidden">
              <h2 className="text-xl font-semibold text-black">NUTRITIONAL</h2>
              <div className="h-1 bg-blue-400 my-2"></div>
              <div className="grid grid-cols-2 gap-1">
                <p className="font-semibold text-black">Serving Size</p>{" "}
                {/* Changed text color */}
                <p className="text-right text-black">1 bar (70ml)</p>{" "}
                {/* Changed text color */}
                <p className="font-semibold text-black">Calories</p>{" "}
                {/* Changed text color */}
                <p className="text-right text-black">100</p>{" "}
                {/* Changed text color */}
                <p className="font-semibold text-black">Total Fat</p>{" "}
                {/* Changed text color */}
                <p className="text-right text-black">2 g</p>{" "}
                {/* Changed text color */}
                <p className="font-semibold text-black">Saturated Fat</p>{" "}
                {/* Changed text color */}
                <p className="text-right text-black">1.5 g</p>{" "}
                {/* Changed text color */}
                <p className="font-semibold text-black">Trans Fat</p>{" "}
                {/* Changed text color */}
                <p className="text-right text-black">0 g</p>{" "}
                {/* Changed text color */}
                <p className="font-semibold text-black">Cholesterol</p>{" "}
                {/* Changed text color */}
                <p className="text-right text-black">0 mg</p>{" "}
                {/* Changed text color */}
                <p className="font-semibold text-black">Sodium</p>{" "}
                {/* Changed text color */}
                <p className="text-right text-black">50 mg</p>{" "}
                {/* Changed text color */}
                <p className="font-semibold text-black">
                  Total Carbohydrate
                </p>{" "}

                <p className="text-right text-black">19 g</p>{" "}

                <p className="font-semibold text-black">Sugars</p>

                <p className="text-right text-black">15 g</p>

                <p className="font-semibold text-black">Fibre</p>
                
                <p className="text-right text-black">0 g</p>
                {/* Changed text color */}
                <p className="font-semibold text-black">Protein</p>{" "}
                {/* Changed text color */}
                <p className="text-right text-black">1 g</p>{" "}
                <p className="font-semibold text-black">Potassium</p>{" "}
                {/* Changed text color */}
                <p className="text-right text-black">75 mg</p>{" "}
                {/* Changed text color */}
                <p className="font-semibold text-black">Calcium</p>{" "}
                {/* Changed text color */}
                <p className="text-right text-black">40 mg</p>{" "}
                {/* Changed text color */}
                <p className="font-semibold text-black">Iron</p>{" "}
                {/* Changed text color */}
                <p className="text-right text-black">0.1 mg</p>{" "}
                {/* Changed text color */}
              </div>
            </div>

            <div className="mb-4 hidden md:block">
              <h2 className="text-xl font-semibold text-black">NUTRITIONAL</h2>
              <div className="h-1 bg-blue-400 my-2"></div>
              <div className="grid grid-cols-4 gap-1">
                <div>
                  <div className="font-semibold text-black">Serving Size</div>
                  <div className="font-semibold text-black">Calories</div>
                  <div className="font-semibold text-black">Total Fat</div>
                  <div className="font-semibold text-black">Saturated Fat</div>
                  <div className="font-semibold text-black">Trans Fat</div>
                  <div className="font-semibold text-black">
                    Total Carbohydrate
                  </div>
                  <div className="font-semibold text-black">Sugars</div>
                </div>
                <div className="mr-2 lg:mr-10">
                  <div className="text-right text-black">1 bar (70ml)</div>
                  <div className="text-right text-black">100</div>
                  <div className="text-right text-black">2 g</div>
                  <div className="text-right text-black">1.5 g</div>
                  <div className="text-right text-black">0 g</div>
                  <div className="text-right text-black">19 g</div>
                  <div className="text-right text-black">15 g</div>
                </div>
                <div className="ml-2 lg:ml-10">
                  <div className="font-semibold text-black">Fibre</div>
                  <div className="font-semibold text-black">Cholesterol</div>
                  <div className="font-semibold text-black">Protein</div>
                  <div className="font-semibold text-black">Sodium</div>
                  <div className="font-semibold text-black">Potassium</div>
                  <div className="font-semibold text-black">Calcium</div>
                  <div className="font-semibold text-black">Iron</div>
                </div>
                <div>
                  <div className="text-right text-black">0 g</div>
                  <div className="text-right text-black">0 mg</div>
                  <div className="text-right text-black">1 g</div>
                  <div className="text-right text-black">50 mg</div>
                  <div className="text-right text-black">75 mg</div>
                  <div className="text-right text-black">40 mg</div>
                  <div className="text-right text-black">0.1 mg</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CoconutMelonaNutrition;
