import React from "react";
import { Link } from "react-router-dom";

function ProductComponent({ imageSrc, link, title }) {
  return (
    <div className="relative">
      <div className="flex justify-center items-center mb-10">
        <div className="relative group">
          <img
            src={imageSrc}
            alt="Product"
            className="w-full h-auto opacity-50 rounded-lg group-hover:opacity-100 transition-opacity duration-300 " // Add border thickness and rounded style
            style={{ zIndex: 50 }}
          />
          <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-center p-2 rounded-md group-hover:bg-opacity-60 transition-bg-opacity duration-300">
            <p className="text-4xl font-bold mb-10">{title}</p>
            <Link to={link}>
              <button
                className="border-2 border-black mt-2 py-1 px-3 rounded-3xl font-bold text-xs"
                style={{ zIndex: 60 }}
              >
                LEARN MORE {">"}
              </button>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProductComponent;
