import React, { useState, useEffect } from "react";
import logo from "../assets/logo/binggraelogo.png";
import instagramIcon from "../assets/socialmedia/instagram.png";
import tiktokIcon from "../assets/socialmedia/tiktok.png";
import { Link } from "react-router-dom";
import emailjs from "emailjs-com";

import {
  LazyLoadComponent,
  LazyLoadImage,
} from "react-lazy-load-image-component";

const Footer = () => {
  const [email, setEmail] = useState("");

  const resetEmailState = () => {
    setEmail('');
  };
  const registerEmail = async (ev) => {
    ev.preventDefault();
    try {
      const templateParams = {
        email,
      };

      // Send email using EmailJS
      const response = await emailjs.send(
        "service_rm3thmm",
        "template_ahmibjb",
        templateParams,
        "j58yxT_KoGkuMCYEp"
      );

      if (response.status === 200) {
        setEmail("");
      } else {
        throw new Error("Email registration failed.");
      }
    } catch (error) {
      console.error(error);
      alert("An error occurred while registering the email.");
      setEmail("");
    }
  };

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  // Add an event listener to all links to scroll to the top
  const addScrollToTopEventListener = () => {
    const links = document.querySelectorAll("Link");

    links.forEach((link) => {
      link.addEventListener("click", scrollToTop);
    });
  };

  // Call the function when the component mounts
  useEffect(() => {
    addScrollToTopEventListener();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <LazyLoadComponent>
      <footer className="text-black ">
        <div className="container mx-auto px-4">
          <div className=" border-t border-gray-600 pt-6  flex flex-wrap items-center justify-between">
            <div className="w-full md:w-1/4 mb-4  flex justify-center items-center text-center">
              <LazyLoadImage
                src={logo}
                alt="Binggrae"
                className="h-12 md:h-20 xl:h-24 xl:mx-0 sm:h-auto sm:mx-auto"
              />
            </div>

            {/* Explore section */}
            <div className="w-full md:w-auto mb-4 md:mb-0 text-center md:text-left">
              <div className="text-center">
                <ul className="text-center md:text-left space-y-2">
                  <li className="group">
                    <Link
                      to="/BingStory"
                      className="text-black font-bold"
                      onClick={scrollToTop}
                    >
                      ABOUT BINGGRAE
                    </Link>
                  </li>

                  <li className="group">
                    <Link
                      to="/Contact"
                      className="text-black font-bold"
                      onClick={scrollToTop}
                    >
                      CONTACT US
                    </Link>
                  </li>

                  <li className="group">
                    <Link
                      to="/PhotoGallery"
                      className="text-black font-bold"
                      onClick={scrollToTop}
                    >
                      OUR GALLERY
                    </Link>
                  </li>
                </ul>
              </div>
            </div>

            {/* Sign up for our Newsletter */}
            <div className="w-full md:w-auto mb-4 md:mb-0 text-center md:text-left">
              <div className="text-center">
                <h4 className="text-lg font-semibold mb-2">
                  Sign up for our Newsletter
                </h4>
                <form
                  onSubmit={resetEmailState}
                  className="md:flex md:items-center md:justify-center"
                >
                  <div className="flex flex-col md:flex-row items-center">
                    <input
                      type="email"
                      placeholder="Enter Email Address"
                      className="border border-gray-400 px-2 py-1 md:mr-2 rounded hover:border-red-600 mb-2 md:mb-0"
                      value={email}
                      onChange={(ev) => setEmail(ev.target.value)}
                    />
                    <button
                      type="submit"
                      className="bg-red-600 text-white px-4 py-1 rounded font-bold"
                    >
                      Subscribe
                    </button>
                  </div>
                </form>
              </div>
            </div>

            {/* Follow Us */}
            <div className="w-full md:w-auto mb-4 md:mb-0 flex items-center justify-center md:justify-end">
              <div className="text-center md:text-right">
                <h4 className="text-lg font-semibold mb-2">Follow Us</h4>
                <div className="flex space-x-4">
                  <a
                    href="https://www.instagram.com/melonacanada"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <LazyLoadImage
                      src={instagramIcon}
                      alt="Instagram Icon"
                      className="w-6 h-6"
                    />
                  </a>
                  <a
                    href="https://www.tiktok.com/@melonacanada"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <LazyLoadImage
                      src={tiktokIcon}
                      alt="tiktok"
                      className="w-6 h-6"
                    />
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="border-t border-gray-600 mt-6 pt-6 text-sm text-center text-black font-semibold">
            &copy; {new Date().getFullYear()} Binggrae Co Ltd. All rights
            reserved.
          </div>
        </div>
      </footer>
    </LazyLoadComponent>
  );
};

export default Footer;
