import React, { useState, useEffect, useRef } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import CostcoIcon from "../assets/stores/costco.png";
import WalmartIcon from "../assets/stores/walmart.png";
import TandTIcon from "../assets/stores/tandt.png";
import SuperstoreIcon from "../assets/stores/Superstore.png";
import NoFrillsIcon from "../assets/stores/NoFrills.png";
import LoblawsIcon from "../assets/stores/Loblaws.png";
import HmartIcon from "../assets/stores/hmart.png";

const images = [
  WalmartIcon,
  TandTIcon,
  SuperstoreIcon,
  NoFrillsIcon,
  LoblawsIcon,
  HmartIcon,
  CostcoIcon,
];

function StoreScrollBar() {
  const [slidesToShow, setSlidesToShow] = useState(getInitialSlidesToShow());
  const [currentSlide, setCurrentSlide] = useState(0);
  const sliderRef = useRef(null);

  useEffect(() => {
    const handleWindowResize = () => {
      setSlidesToShow(getInitialSlidesToShow());
    };

    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      if (sliderRef.current) {
        setCurrentSlide((prevSlide) =>
          prevSlide === images.length - 1 ? 0 : prevSlide + 1
        );
        sliderRef.current.slickNext();
      }
    }, 2000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  // Function to determine the initial slidesToShow value using Tailwind CSS breakpoints
  function getInitialSlidesToShow() {
    if (window.innerWidth >= 1536) {
      return 6; // For 2xl screens
    } else if (window.innerWidth >= 1280) {
      return 5; // For xl screens
    } else if (window.innerWidth >= 1024) {
      return 4; // For lg screens
    } else if (window.innerWidth >= 768) {
      return 3; // For md screens
    } else {
      return 2; // For sm and xs screens
    }
  }

  const settings = {
    slidesToShow: slidesToShow,
    slidesToScroll: 1,
    variableWidth: false,
    centerMode: false,
    arrows: false,
    initialSlide: currentSlide,
  };

  return (
    <>
      <div>
        <div className="mt-4 text-center md:mt-10">
          <h1 className="mb-4 text-2xl font-bold md:text-5xl">FIND US AT</h1>
        </div>
        <div className="z-10 p-2 mt-2 mb-2 md:p-4 md:mt-4 md:mb-10">
          <Slider {...settings} ref={sliderRef}>
            {images.map((image, index) => (
              <div
                key={index}
                className={`text-center ${
                  slidesToShow === 2 ? "mb-4" : slidesToShow === 3 ? "mb-3" : ""
                }`}
              >
                <img
                  src={image}
                  alt={`${index}`}
                  className={`${
                    slidesToShow === 2 ? "w-36 h-24" : "w-52 h-36"
                  } object-contain`}
                />
              </div>
            ))}
          </Slider>
        </div>
      </div>
    </>
  );
}

export default StoreScrollBar;
