import React, { useState } from "react";
import Image8 from "../assets/homepage/Croppedslider8.jpg";
import SponsorshipPopup from "../components/SponsorshipPopup";
import { LazyLoadComponent, LazyLoadImage } from "react-lazy-load-image-component";

function Sponsorship() {
  const [popupOpen, setPopupOpen] = useState(false);

  const handleLearnMoreClick = () => {
    setPopupOpen(!popupOpen);
  };

  return (
    <React.Fragment>
      <LazyLoadComponent>
        <div className="relative max-h-[80vh] w-screen overflow-hidden bg-white mt-10 ">
          <LazyLoadImage
            src={Image8}
            alt="Slider Banner"
            className={`w-full h-full object-cover brightness-40`}
          />
          <div className="absolute top-0 left-0 w-full h-full bg-black bg-opacity-60"></div>
          <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-2/4 text-center w-full text-white font-bold text-xl sm:text-5xl z-10 -translate-y-full ">
            BUILDING A COMMUNITY
            <br />
          </div>
          <div className="absolute bottom-1/4 left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-10">
            <button
              className="border-2 border-white py-1 px-3 rounded-lg text-white font-bold text-sm sm:text-xl"
              onClick={handleLearnMoreClick}
            >
              {popupOpen ? "CLOSE" : "LEARN MORE >"}
            </button>
          </div>
        </div>
        <div className="bg-white">{popupOpen && <SponsorshipPopup />}</div>
      </LazyLoadComponent>
    </React.Fragment>
  );
}

export default Sponsorship;
