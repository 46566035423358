import React from "react";
import StrawberrySamanco from "../../assets/SamancoProductPage/NutritionPage/Samanco Strawberry.png";

const StrawberrySamancoNutrition = () => {
  return (
    <div className="flex flex-col items-center justify-center px-4 pb-10 mb-10 sm:px-8 md:px-16 z-3">
      <div className="w-full max-w-screen-xl mx-auto rounded-lg">
        <div className="flex flex-col items-center justify-center md:flex-row">
          <img
            src={StrawberrySamanco}
            alt="Food Product"
            className="object-cover w-64 h-auto mb-4 sm:w-52 md:w-64 lg:w-80 md:mb-0 md:mr-8"
          />
          <div className="mb-4">
            <h2 className="text-xl font-semibold text-black">DESCRIPTION</h2>
            <div className="h-1 my-2 bg-red-300"></div>
            <p className="text-lg text-black">
              Experience the berry fields of summer with Samanco Strawberry—a fish-shaped ice waffle sandwich that encapsulates the essence of the summer season. As you bite into the crispy exterior, you're greeted by the refreshing burst of ripe strawberries. It's a journey into fruity indulgence, reimagined in the form of a delightful treat.
            </p>
          </div>
        </div>
        <div className="mb-4">
          <h2 className="text-xl font-semibold text-black">INGREDIENTS</h2>
          <div className="h-1 my-2 bg-red-300"></div>
          <p className="text-lg text-black">
            Water, Sugar (strawberry syrup, sugar, corn syrup, lactose), Biscuit (wheat flour, corn starch, palm oil, whey, baking powder, hydrogenated coconut oil, sodium bicarbonate, milk cream, calcium hydroxide, carotene, sodium caseinate, ammonium bicarbonate, soy lecithin), Strawberry, Coconut oil, Skim milk powder, Whey powder, Modified tapioca starch, Mono-and-diglycerides, Pectin, Artificial flavors, Citric acid, Cellulose gum, Salt, Guar gum, Polysorbate 80, Carrageenan, Calcium phosphate, Locust bean gum, Tartrazine, Sodium citrate, Beet red, Sunset yellow FCF
            <br />
            <br />
            <span className="font-bold">Contains:</span> Wheat(Gluten), Soy, Milk
          </p>
        </div>
        <div className="mb-4 md:hidden">
          <h2 className="text-xl font-semibold text-black">NUTRITIONAL</h2>
          <div className="h-1 my-2 bg-red-300"></div>
          <div className="grid grid-cols-2 gap-2">
            <p className="font-semibold text-black">Serving Size</p>
            <p className="text-right text-black">1 Sandwich (150ml)</p>
            <p className="font-semibold text-black">Calories</p>
            <p className="text-right text-black">200</p>
            <p className="font-semibold text-black">Total Fat</p>
            <p className="text-right text-black">7 g</p>
            <p className="font-semibold text-black">Saturated Fat</p>
            <p className="text-right text-black">5 g</p>
            <p className="font-semibold text-black">Trans Fat</p>
            <p className="text-right text-black">0 g</p>
            <p className="font-semibold text-black">Cholesterol</p>
            <p className="text-right text-black">0 mg</p>
            <p className="font-semibold text-black">Sodium</p>
            <p className="text-right text-black">65 mg</p>
            <p className="font-semibold text-black">Total Carbohydrate</p>
            <p className="text-right text-black">33 g</p>
            <p className="font-semibold text-black">Sugars</p>
            <p className="text-right text-black">22 g</p>
            <p className="font-semibold text-black">Protein</p>
            <p className="text-right text-black">2 g</p>
            <p className="font-semibold text-black">Vitamin D</p>
            <p className="text-right text-black">0 g</p>
            <p className="font-semibold text-black">Potassium</p>
            <p className="text-right text-black">75 mg</p>
            <p className="font-semibold text-black">Calcium</p>
            <p className="text-right text-black">50 mg</p>
            <p className="font-semibold text-black">Iron</p>
            <p className="text-right text-black">0.1 mg</p>
          </div>
        </div>

        <div className="hidden md:block">
          <h2 className="text-xl font-semibold text-black">NUTRITIONAL</h2>
          <div className="h-1 my-2 bg-red-400"></div>
          <div className="grid grid-cols-4 gap-2">
            <div>
              <p className="font-semibold text-black">Serving Size</p>
              <p className="font-semibold text-black">Calories</p>
              <p className="font-semibold text-black">Total Fat</p>
              <p className="font-semibold text-black">Saturated Fat</p>
              <p className="font-semibold text-black">Trans Fat</p>
              <p className="font-semibold text-black">Cholesterol</p>
              <p className="font-semibold text-black">Sodium</p>
            </div>
            <div className="md:mr-5 lg:mr-20">
              <p className="text-right text-black">1 Sandwich (150ml)</p>
              <p className="text-right text-black">200</p>
              <p className="text-right text-black">7 g</p>
              <p className="text-right text-black">5 g</p>
              <p className="text-right text-black">0 g</p>
              <p className="text-right text-black">0 mg</p>
              <p className="text-right text-black">65 mg</p>
            </div>
            <div className="md:ml-5 lg:mr-20">
              <p className="font-semibold text-black">Total Carbohydrate</p>
              <p className="font-semibold text-black">Sugars</p>
              <p className="font-semibold text-black">Protein</p>
              <p className="font-semibold text-black">Vitamin D</p>
              <p className="font-semibold text-black">Potassium</p>
              <p className="font-semibold text-black">Calcium</p>
              <p className="font-semibold text-black">Iron</p>
            </div>
            <div>
              <p className="text-right text-black">33 g</p>
              <p className="text-right text-black">22 g</p>
              <p className="text-right text-black">2 g</p>
              <p className="text-right text-black">0 g</p>
              <p className="text-right text-black">75 mg</p>
              <p className="text-right text-black">50 mg</p>
              <p className="text-right text-black">0.1 mg</p>
            </div>
          </div>
        </div>

      </div>
    </div>
  );
};

export default StrawberrySamancoNutrition;
