import React, { useState } from "react";
import RedBeanSamanco from "../assets/SamancoProductPage/RedBeanSamanco.png";
import ChocolateSamanco from "../assets/SamancoProductPage/ChocolateSamanco.png";
import GreenTeaSamanco from "../assets/SamancoProductPage/GreenTeaSamanco.png";
import StrawberrySamanco from "../assets/SamancoProductPage/StrawberrySamanco.png";
import RedBeanSamancoIcon from "../assets/SamancoIcons/RedBeanSamancoIcon.png";
import ChocolateSamancoIcon from "../assets/SamancoIcons/ChocolateSamancoIcon.png";
import GreenTeaSamancoIcon from "../assets/SamancoIcons/GreenTeaSamancoIcon.png";
import StrawberrySamancoIcon from "../assets/SamancoIcons/StrawberrySamancoIcon.png";
import RedBeanSamancoNutrition from "../components/nutritionalInfoSamanco/RedBeanSamancoNutrition.jsx";
import ChocolateSamancoNutrition from "../components/nutritionalInfoSamanco/ChocolateSamancoNutrition.jsx";
import StrawberrySamancoNutrition from "../components/nutritionalInfoSamanco/StrawberrySamancoNutrition.jsx";
import GreenTeaSamancoNutrition from "../components/nutritionalInfoSamanco/GreenTeaSamancoNutrition.jsx";
import {
  LazyLoadComponent,
  LazyLoadImage,
} from "react-lazy-load-image-component";

function SamancoProductPage() {
  const SamancoFlavours = [
    {
      image: RedBeanSamanco,
      icon: RedBeanSamancoIcon,
      nutrition: <RedBeanSamancoNutrition />,
      backgroundColour: "#fecaca", // Add background color for Red Bean flavor
    },
    {
      image: ChocolateSamanco,
      icon: ChocolateSamancoIcon,
      nutrition: <ChocolateSamancoNutrition />,
      backgroundColour: "#fef3c7", // Add background color for Chocolate flavor
    },
    {
      image: GreenTeaSamanco,
      icon: GreenTeaSamancoIcon,
      nutrition: <GreenTeaSamancoNutrition />,
      backgroundColour: "#dcfce7", // Add background color for Green Tea flavor
    },
    {
      image: StrawberrySamanco,
      icon: StrawberrySamancoIcon,
      nutrition: <StrawberrySamancoNutrition />,
      backgroundColour: "#fee2e2", // Add background color for Strawberry flavor
    },
  ];

  const [currentFlavorIndex, setCurrentFlavorIndex] = useState(0);

  const currentFlavor = SamancoFlavours[currentFlavorIndex];

  const firstRowIcons = [
    RedBeanSamancoIcon,
    ChocolateSamancoIcon,
    GreenTeaSamancoIcon,
    StrawberrySamancoIcon,
  ];

  return (
    <React.Fragment>
       <div
       
        style={{ backgroundColor: currentFlavor.backgroundColour }}
      >
        <div className="pt-20">
          <div className="z-3">
            <LazyLoadImage
              src={currentFlavor.image}
              alt="Samanco Product"
              className="w-full object-cover rounded-lg md:rounded-xl lg:h-[110vh]"
            />

            <div className="flex justify-center mt-4">
              {firstRowIcons.map((icon, index) => (
                <button
                  key={index}
                  className={`mx-2 ${
                    currentFlavorIndex === index
                      ? "transform scale-110"
                      : "transition-transform hover:scale-110"
                  }`}
                  onClick={() => setCurrentFlavorIndex(index)}
                >
                  <LazyLoadImage
                    src={icon}
                    alt={`Samanco Flavor Icon ${index}`}
                    className="w-20 h-20 animate-bounce"
                  />
                </button>
              ))}
            </div>
          </div>
        </div>
        <LazyLoadComponent>
          <div className="mb-8 md:mb-16 lg:mb-20 -z-4">
            {currentFlavor.nutrition}
          </div>
        </LazyLoadComponent>
      </div>
    </React.Fragment>
  );
}

export default SamancoProductPage;
