import React, { useState } from "react";
import melonabars from "../assets/products/Melonas/Melonas3.jpg";
import emailjs from "emailjs-com";

function Contact() {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();

    const templateParams = {
      from_name: name,
      from_email: email,
      message: message,
    };

    // Send email using EmailJS
    // EmailJS info
    // Email: binggraecanada@gmail.com
    // Password: MelonaCanada!
    emailjs
      .send(
        "service_rm3thmm",
        "template_rpvl27f",
        templateParams,
        "j58yxT_KoGkuMCYEp"
      )
      .then((response) => {
        console.log("Email sent successfully!", response.text);

        setName("");
        setEmail("");
        setMessage("");
      })
      .catch((error) => {
        console.error("Error sending email:", error);
      });
  };

  return (
    <div className="flex flex-col pt-16 md:flex-row">
      <div className="hidden lg:block w-full max-h-[38rem] overflow-hidden">
        <img
          className="max-h-[38rem] w-full object-cover"
          src={melonabars}
          alt="Contact Us"
        />
      </div>


      {/* Contact Form section*/}
      <div className="w-full p-8 h-fit md:w-1/2 ">
      
        <h1 className="text-4xl font-bold tracking-wider text-black md:text-5xl lg:text-7xl">
          Contact Us
        </h1>
        <h2 className="mt-4 text-base leading-8 text-gray-800 md:text-lg md:w-full">
          Please feel free to contact us any time if you have any questions or
          comments. We'd love to hear from you.
        </h2>
        <form onSubmit={handleSubmit} className="mt-8 space-y-4 ">
          {/* Name input*/}
          <div className="flex flex-col">
            <input
              type="text"
              id="name"
              value={name}
              onChange={(e) => setName(e.target.value)}
              className="w-full py-5 pl-4 mt-3 text-base text-gray-800 border-2 border-black rounded-lg hover:border-red-600 focus:border-red-600 focus:outline-none"
              placeholder="Name"
              required
            />
          </div>


          {/* Email input*/}
          <div className="flex flex-col">
            <input
              type="email"
              id="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className="w-full py-5 pl-4 mt-3 text-base text-gray-800 border-2 border-black rounded-lg hover:border-red-600 focus:border-red-600 focus:outline-none"
              placeholder="Email"
              required
            />
          </div>


          {/* Message input*/}
          <div className="flex flex-col">
            <textarea
              id="message"
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              className="w-full py-5 pl-4 mt-3 text-base text-gray-800 border-2 border-black rounded-lg hover:border-red-600 focus:border-red-600 focus:outline-none"
              rows={4}
              placeholder="Message"
              required
            />
          </div>


          {/* Submit button*/}
          <button
            type="submit"
            className="px-6 py-3 font-bold text-white bg-red-600 rounded-lg hover:bg-red-600"
          >
            Submit
          </button>
        </form>
      </div>
    </div>

  );
}

export default Contact;

