import React from "react";
import PowerCapSoda from "../../assets/PowerCapProductPage/Powercap Soda.png";

const SodaPowerCapNutrition = () => {
  return (
    <div className="flex flex-col items-center justify-center px-4 pb-10 mb-10 sm:px-8 md:px-16 z-3">
      <div className="w-full max-w-screen-xl mx-auto rounded-lg">
        <div className="flex flex-col items-center justify-center md:flex-row">
          <img
            src={PowerCapSoda}
            alt="Food Product"
            className="w-16 w-24 h-auto mb-10 w-15 md:mr-24"
          />
          <div className="mb-4">
            <h2 className="text-xl font-semibold text-black">DESCRIPTION</h2>
            <div className="h-1 my-2 bg-blue-300"></div>
            <p className="text-lg text-black">
              Pop the cap on a world of bubbly delight with our SodaCap. Twist open the cap and experience the lively fizz of cream soda, encapsulated in a taste that's both nostalgically familiar and refreshingly new. With a flavour profile similar to lemon-lime, but with a sweeter edge, this bubbly sensation captures the playful fizz and tang of your favourite soda.
            </p>
          </div>
        </div>
        <div className="mb-4">
          <h2 className="text-xl font-semibold text-black">INGREDIENTS</h2>
          <div className="h-1 my-2 bg-blue-300"></div>
          <p className="text-lg text-black">
          Water, Sugar, High Fructose Corn Syrup, Corn Syrup, Coconut Oil, Whey Powder, Artificial Flavors, Mono- and Diglycerides, Salt, Guar Gum, Carrageenan, Cellulose Gum, FD&C Blue No.1

            <br />
            <br />
            <span className="font-bold">Contains:</span> Milk Ingredients
          </p>
        </div>
        <div className="mb-4 md:hidden">
          <h2 className="text-xl font-semibold text-black">NUTRITIONAL</h2>
          <div className="h-1 my-2 bg-blue-300"></div>
          <div className="grid grid-cols-2 gap-1">
            <p className="font-semibold text-black">Serving Size</p>
            <p className="text-right text-black">1 Tube (130ml)</p>
            <p className="font-semibold text-black">Calories</p>
            <p className="text-right text-black">130</p>
            <p className="font-semibold text-black">Total Fat</p>
            <p className="text-right text-black">3.5 g</p>
            <p className="font-semibold text-black">Saturated Fat</p>
            <p className="text-right text-black">3 g</p>
            <p className="font-semibold text-black">Trans Fat</p>
            <p className="text-right text-black">0 g</p>
            <p className="font-semibold text-black">Cholesterol</p>
            <p className="text-right text-black">20 mg</p>
            <p className="font-semibold text-black">Sodium</p>
            <p className="text-right text-black">35 mg</p>
            <p className="font-semibold text-black">Total Carbohydrate</p>
            <p className="text-right text-black">25 g</p>
            <p className="font-semibold text-black">Sugars</p>
            <p className="text-right text-black">22 g</p>
            <p className="font-semibold text-black">Protein</p>
            <p className="text-right text-black">0 g</p>
            <p className="font-semibold text-black">Vitamin D</p>
            <p className="text-right text-black">0 g</p>
            <p className="font-semibold text-black">Potassium</p>
            <p className="text-right text-black">0 mg</p>
            <p className="font-semibold text-black">Calcium</p>
            <p className="text-right text-black">0 mg</p>
            <p className="font-semibold text-black">Iron</p>
            <p className="text-right text-black">0 mg</p>
            
          </div>
        </div>

        <div className="hidden md:block">
  <h2 className="text-xl font-semibold text-black">NUTRITIONAL</h2>
  <div className="h-1 my-2 bg-blue-300"></div>
  <div className="grid grid-cols-4 gap-1">
    <div>
      <p className="font-semibold text-black">Serving Size</p>
      <p className="font-semibold text-black">Calories</p>
      <p className="font-semibold text-black">Total Fat</p>
      <p className="font-semibold text-black">Saturated Fat</p>
      <p className="font-semibold text-black">Trans Fat</p>
      <p className="font-semibold text-black">Cholesterol</p>
      <p className="font-semibold text-black">Sodium</p>
    </div>
    <div className="md:mr-5 lg:mr-20">
      <p className="text-right text-black">1 Tube (130ml)</p>
      <p className="text-right text-black">130</p>
      <p className="text-right text-black">3.5 g</p>
      <p className="text-right text-black">3 g</p>
      <p className="text-right text-black">0 g</p>
      <p className="text-right text-black">20 mg</p>
      <p className="text-right text-black">35 mg</p>
    </div>
    <div className="md:ml-5 lg:mr-20">
      <p className="font-semibold text-black">Total Carbohydrate</p>
      <p className="font-semibold text-black">Sugars</p>
      <p className="font-semibold text-black">Protein</p>
      <p className="font-semibold text-black">Vitamin D</p>
      <p className="font-semibold text-black">Potassium</p>
      <p className="font-semibold text-black">Calcium</p>
      <p className="font-semibold text-black">Iron</p>
    </div>
    <div>
      <p className="text-right text-black">25 g</p>
      <p className="text-right text-black">22 g</p>
      <p className="text-right text-black">0 g</p>
      <p className="text-right text-black">0 g</p>
      <p className="text-right text-black">0 mg</p>
      <p className="text-right text-black">0 mg</p>
      <p className="text-right text-black">0 mg</p>
    </div>
  </div>
</div>

      </div>
    </div>
  );
};

export default SodaPowerCapNutrition;
