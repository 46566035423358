import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Binggraelogo from "../assets/logo/binggraelogo.png";

function Header() {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [showAboutUsDropdown, setShowAboutUsDropdown] = useState(false);
  const [showProductsDropdown, setShowProductsDropdown] = useState(false);

  // Show/hide the "About Us" dropdown
  const handleAboutUsDropdown = () => {
    setShowAboutUsDropdown(!showAboutUsDropdown);
  };

  // Show/hide the "Products" dropdown
  const handleProductsDropdown = () => {
    setShowProductsDropdown(!showProductsDropdown);
  };

  const toggleMobileMenu = () => {
    setMobileMenuOpen(!mobileMenuOpen);
    setShowAboutUsDropdown(false);
    setShowProductsDropdown(false);

    scrollToTop();
  };

  // Function to scroll to the top of the page
  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  // Add an event listener to all links to scroll to the top
  const addScrollToTopEventListener = () => {
    const links = document.querySelectorAll("Link");

    links.forEach((link) => {
      link.addEventListener("click", scrollToTop);
    });
  };

  // Call the function when the component mounts
  useEffect(() => {
    addScrollToTopEventListener();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <header className="fixed top-0 left-0 right-0 py-6 z-50 bg-white shadow-lg lg:py-5">
      <nav className="container flex items-center justify-between mx-auto">
        <div className="flex items-center">
          <Link
            to="/"
            className="relative font-bold text-black hover-animation"
            onClick={scrollToTop}
          >
            <img
              src={Binggraelogo}
              alt="Binggrae logo"
              className="h-8 pl-5 lg:h-10 lg:pl-0"
            />
          </Link>
        </div>
        {/* Desktop Navigation */}
        <ul className="hidden space-x-16 md:flex">
          <li
            className="relative group group-hover:block"
            onMouseEnter={handleAboutUsDropdown}
            onMouseLeave={() => setShowAboutUsDropdown(false)}
          >
            <Link
              to="/"
              className="relative font-bold text-black hover-animation"
              onClick={scrollToTop}
            >
              LEARN ABOUT US
              <span className="absolute bottom-0 left-0 w-0 h-0 bg-red-600 transition-all duration-300 group-hover:w-full group-hover:h-0.5"></span>
            </Link>
            {showAboutUsDropdown && (
              <ul className="absolute w-full py-2 bg-white border rounded-lg shadow-lg">
                <li>
                  <Link
                    to="/BingStory"
                    className="block px-4 py-2 font-semibold text-black hover:bg-gray-100"
                    onClick={scrollToTop}
                  >
                    BINGGRAE
                  </Link>
                </li>
                <li>
                  <Link
                    to="/MelonaStory"
                    className="block px-4 py-2 font-semibold text-black hover:bg-gray-100"
                    onClick={scrollToTop}
                  >
                    MELONA
                  </Link>
                </li>
                <li>
                  <Link
                    to="/PhotoGallery"
                    className="block px-4 py-2 font-semibold text-black hover:bg-gray-100"
                    onClick={scrollToTop}
                  >
                    OUR GALLERY
                  </Link>
                </li>
              </ul>
            )}
          </li>
          <li
            className="relative group group-hover:block"
            onMouseEnter={handleProductsDropdown}
            onMouseLeave={() => setShowProductsDropdown(false)}
          >
            <Link
              to="/"
              className="font-bold text-black hover-animation"
              onClick={scrollToTop}
            >
              OUR PRODUCTS
              <span className="absolute bottom-0 left-0 w-0 h-0 bg-red-600 transition-all duration-300 group-hover:w-full group-hover:h-0.5"></span>
            </Link>
            {showProductsDropdown && (
              <ul className="absolute py-2 bg-white border rounded-lg shadow-lg ">
                <li>
                  <Link
                    to="/MelonaBarsProducts"
                    className="block px-4 py-2 font-semibold text-black hover:bg-gray-100"
                    onClick={scrollToTop}
                  >
                    MELONA BARS
                  </Link>
                </li>
                <li>
                  <Link
                    to="/CostcoMelona"
                    className="block px-4 py-2 font-semibold text-black hover:bg-gray-100"
                    onClick={scrollToTop}
                  >
                    COSTCO MELONA PACK
                  </Link>
                </li>
                <li>
                  <Link
                    to="/MelonaMilk"
                    className="block px-4 py-2 font-semibold text-black hover:bg-gray-100"
                    onClick={scrollToTop}
                  >
                    FLAVOURED MILK
                  </Link>
                </li>
                <li>
                  <Link
                    to="/Samanco"
                    className="block px-4 py-2 font-semibold text-black hover:bg-gray-100"
                    onClick={scrollToTop}
                  >
                    SAMANCO
                  </Link>
                </li>
                <li>
                  <Link
                    to="/BBbig"
                    className="block px-4 py-2 font-semibold text-black hover:bg-gray-100"
                    onClick={scrollToTop}
                  >
                    B.B.BIG
                  </Link>
                </li>
                <li>
                  <Link
                    to="/PowerCap"
                    className="block px-4 py-2 font-semibold text-black hover:bg-gray-100"
                  >
                    POWER CAP
                  </Link>
                </li>
                <li>
                  <Link
                    to="/Pangtoa"
                    className="block px-4 py-2 font-semibold text-black hover:bg-gray-100"
                    onClick={scrollToTop}
                  >
                    PANGTOA
                  </Link>
                </li>
              </ul>
            )}
          </li>
          <li className="relative group group-hover:block">
            <Link
              to="/Contact"
              className="font-bold text-black hover-animation"
              onClick={scrollToTop}
            >
              CONTACT US
              <span className="absolute bottom-0 left-0 w-0 h-0 bg-red-600 transition-all duration-300 group-hover:w-full group-hover:h-0.5"></span>
            </Link>
          </li>
        </ul>

        {/* Mobile Menu Button */}
        <button
          className={`md:hidden text-black font-bold mr-10 relative ${
            mobileMenuOpen ? "open" : ""
          } ${mobileMenuOpen ? "close" : ""}`}
          onClick={toggleMobileMenu}
        >
          <svg
            className="w-6 h-6"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d={
                mobileMenuOpen
                  ? "M6 18L18 6M6 6l12 12"
                  : "M4 6h16M4 12h16M4 18h16"
              }
            ></path>
          </svg>
        </button>

        {/* Mobile Menu */}
        <div
          className={`md:hidden fixed top-0 left-0 w-full h-full bg-white border-l border-gray-200 shadow-lg p-4 transform transition-transform duration-300 ${
            mobileMenuOpen ? "translate-x-0" : "-translate-x-full"
          }`}
        >
          <button
            className="absolute font-bold text-black md:hidden top-4 right-4"
            onClick={toggleMobileMenu}
          >
            <svg
              className="w-6 h-6"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M6 18L18 6M6 6l12 12"
              ></path>
            </svg>
          </button>
          {/* Menu Items */}
          <ul className="flex flex-col mt-10 space-y-4">
            <li>
              <Link
                to="/"
                className="font-bold text-black hover-animation"
                onClick={toggleMobileMenu}
              >
                HOME
              </Link>
            </li>
            <li className="relative group">
              <button
                className="font-bold text-black hover-animation"
                onClick={handleAboutUsDropdown}
              >
                LEARN ABOUT US
              </button>
              {showAboutUsDropdown && (
                <ul className="ml-4 space-y-2">
                  <li>
                    <Link
                      to="/BingStory"
                      className="block font-semibold text-black hover:text-gray-500"
                      onClick={toggleMobileMenu}
                    >
                      Binggrae
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/MelonaStory"
                      className="block font-semibold text-black hover:text-gray-500"
                      onClick={toggleMobileMenu}
                    >
                      Melona
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="PhotoGallery"
                      className="block font-semibold text-black hover:text-gray-500"
                      onClick={toggleMobileMenu}
                    >
                      Our Gallery
                    </Link>
                  </li>
                  {/* Add more story categories as needed */}
                </ul>
              )}
            </li>
            <li className="relative group">
              <button
                className="font-bold text-black hover-animation"
                onClick={handleProductsDropdown}
              >
                PRODUCTS
              </button>
              {showProductsDropdown && (
                <ul className="ml-4 space-y-2">
                  <li>
                    <Link
                      to="/MelonaBarsProducts"
                      className="block font-semibold text-black hover:text-gray-500"
                      onClick={toggleMobileMenu}
                    >
                      Melona Bars
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/CostcoMelona"
                      className="block font-semibold text-black hover:text-gray-500"
                      onClick={toggleMobileMenu}
                    >
                      Costco Melona Pack
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/MelonaMilk"
                      className="block font-semibold text-black hover:text-gray-500"
                      onClick={toggleMobileMenu}
                    >
                      Flavoured Milk
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/Samanco"
                      className="block font-semibold text-black hover:text-gray-500"
                      onClick={toggleMobileMenu}
                    >
                      Samanco
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/BBbig"
                      className="block font-semibold text-black hover:text-gray-500"
                      onClick={toggleMobileMenu}
                    >
                      B.B.Big
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/PowerCap"
                      className="block font-semibold text-black hover:text-gray-500"
                      onClick={toggleMobileMenu}
                    >
                      Powercap
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/Pangtoa"
                      className="block font-semibold text-black hover:text-gray-500"
                      onClick={toggleMobileMenu}
                    >
                      Pangtoa
                    </Link>
                  </li>
                </ul>
              )}
            </li>
            <li>
              <Link
                to="/Contact"
                className="font-bold text-black hover-animation"
                onClick={toggleMobileMenu}
              >
                CONTACT US
              </Link>
            </li>
          </ul>
        </div>
      </nav>
    </header>
  );
}
export default Header;
