import React from "react";
import RedBeanIcon from "../../assets/BBbigProductPage/RedBeanBBbig.png";

const RedBeanBBbigNutritionalInfo = () => {
  return (
    <div className="flex flex-col items-center justify-center px-4 pb-10 mb-10 sm:px-8 md:px-16 z-3 ">
      <div className="w-full max-w-screen-xl rounded-lg">
        <div className="flex flex-col items-center justify-center md:flex-row">
          <img
            src={RedBeanIcon}
            alt="Food Product"
            className="w-24 ml-10 mr-10 md:-ml-20 md:w-60"
          />
          <div className="mt-4 ml-0 md:mt-0 md:ml-4">
            {" "}
            {/* Adjusted margin */}
            <div className="mb-4">
              <h2 className="text-xl font-semibold text-black">DESCRIPTION</h2>
              <div className="h-1 my-2 bg-red-800"></div>
              <p className="text-black text-m">
                Experience a taste of tradition with our B.B.Big Red Bean.
                Released in 1975, delve into the traditional delight that offers
                sweet whole red bean wrapped in a gritty like embrace. Red
                bean's nutty sweetness, is a tribute to the flavours that have
                stood the test of time, offering a moment of pure indulgence.
              </p>
            </div>
            <div className="mb-4">
              <h2 className="text-xl font-semibold text-black">INGREDIENTS</h2>
              <div className="h-1 my-2 bg-red-800"></div>
              <p className="text-black text-m">
                Water, Sweetened Red Bean (Sugar, Red Bean, Water), Sugar, Corn
                Syrup, Boiled Red Bean Powder, Skim Milk Powder, Butter, High
                Fructose Corn Syrup, Waxy Corn Starch, Whey Powder, Salt, Guar
                Gum, Artificial Flavour
                <br />
                <br />
                <span className="font-bold">Contains:</span> Milk
              </p>
            </div>
            <div className="mb-4 md:hidden">
              <h2 className="text-xl font-semibold text-black">NUTRITIONAL</h2>
              <div className="h-1 my-2 bg-red-800"></div>
              <div className="grid grid-cols-2 gap-1">
                <p className="font-semibold text-black">Serving Size</p>
                <p className="text-right text-black">1 bar (70ml)</p>
                <p className="font-semibold text-black">Calories</p>
                <p className="text-right text-black">130</p>
                <p className="font-semibold text-black">Total Fat</p>
                <p className="text-right text-black">1.5 g</p>
                <p className="font-semibold text-black">Saturated Fat</p>
                <p className="text-right text-black">1 g</p>
                <p className="font-semibold text-black">Trans Fat</p>
                <p className="text-right text-black">0 g</p>
                <p className="font-semibold text-black">Cholesterol</p>
                <p className="text-right text-black">Less than 5 mg</p>
                <p className="font-semibold text-black">Sodium</p>
                <p className="text-right text-black">55 mg</p>
                <p className="font-semibold text-black">Total Carbohydrate</p>
                <p className="text-right text-black">28 g</p>
                <p className="font-semibold text-black">Sugars</p>
                <p className="text-right text-black">19 g</p>
                <p className="font-semibold text-black">Protein</p>
                <p className="text-right text-black">3 g</p>
                <p className="font-semibold text-black">Vitamin D</p>
                <p className="text-right text-black">0 g</p>
                <p className="font-semibold text-black">Potassium</p>
                <p className="text-right text-black">0 mg</p>
                <p className="font-semibold text-black">Calcium</p>
                <p className="text-right text-black">30 mg</p>
                <p className="font-semibold text-black">Iron</p>
                <p className="text-right text-black">0 mg</p>
              </div>
            </div>

            <div className="hidden mb-4 md:block">
  <h2 className="text-xl font-semibold text-black">NUTRITIONAL</h2>
  <div className="h-1 my-2 bg-red-800"></div>
  <div className="grid grid-cols-4 gap-1">
    <div>
      <div className="font-semibold text-black">Serving Size</div>
      <div className="font-semibold text-black">Calories</div>
      <div className="font-semibold text-black">Total Fat</div>
      <div className="font-semibold text-black">Saturated Fat</div>
      <div className="font-semibold text-black">Trans Fat</div>
      <div className="font-semibold text-black">Cholesterol</div>
      <div className="font-semibold text-black">Sodium</div>
    </div>
    <div className="mr-2 lg:mr-10">
      <div className="text-right text-black">1 bar (70ml)</div>
      <div className="text-right text-black">130</div>
      <div className="text-right text-black">1.5 g</div>
      <div className="text-right text-black">1 g</div>
      <div className="text-right text-black">0 g</div>
      <div className="text-right text-black">less than 5 mg</div>
      <div className="text-right text-black">55 mg</div>
    </div>
    <div className="ml-2 lg:ml-10">
      <div className="font-semibold text-black">Total Carbohydrate</div>
      <div className="font-semibold text-black">Sugars</div>
      <div className="font-semibold text-black">Protein</div>
      <div className="font-semibold text-black">Vitamin D</div>
      <div className="font-semibold text-black">Potassium</div>
      <div className="font-semibold text-black">Calcium</div>
      <div className="font-semibold text-black">Iron</div>
    </div>
    <div>
      <div className="text-right text-black">28 g</div>
      <div className="text-right text-black">19 g</div>
      <div className="text-right text-black">3 g</div>
      <div className="text-right text-black">0 g</div>
      <div className="text-right text-black">0 mg</div>
      <div className="text-right text-black">30 mg</div>
      <div className="text-right text-black">0 mg</div>
    </div>
  </div>
</div>

          </div>
        </div>
      </div>
    </div>
  );
};

export default RedBeanBBbigNutritionalInfo;
