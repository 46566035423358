import React from "react";
import CoffeeMilk from "../../assets/MelonaMilkNutritionImages/CoffeeMilkIcon.png";

function CoffeeMilkNutritionalInfo() {
  return (
    <div className="flex flex-col items-center justify-center px-4 pb-20 mb-10 sm:px-8 md:px-16 z-70">
      {/* Set a maximum width for the component */}
      <div className="w-full max-w-screen-xl mx-auto rounded-lg">
        <div className="flex flex-col items-center justify-center md:flex-row">
          {/* CoffeeMilk Image */}
          <div className="">
            <img
              src={CoffeeMilk}
              alt="Food Product"
              className="w-64 h-auto ml-6 -ml-10 md:mr-52 lg:-ml-0"
            />
          </div>
          {/* Description */}
          <div className="">
            <h2 className="text-xl font-semibold text-black ">DESCRIPTION</h2>
            <div className="h-1 my-2 bg-yellow-300 "></div>
            <p className="mb-4 text-lg text-black ">
              Rise and shine with our Coffee Flavoured Milk – the perfect
              wake-up call for your taste buds. It's a symphony of bold coffee
              notes and the velvety smoothness of milk, coming together to
              create a beverage that energizes and comforts. Whether you start
              your day or need a midday pick-me-up, this coffee-infused delight
              is your perfect companion.
            </p>
          </div>
        </div>
        {/* Ingredients */}
        <div className="mb-4">
          <h2 className="text-xl font-semibold text-black">INGREDIENTS</h2>
          <div className="h-1 my-2 bg-yellow-300"></div>
          <p className="mb-10 text-lg text-black">
            Milk, Water, Sugar, Dextrin, Artificial Flavors, Coffee Powder,
            Mono-and Diglycerides, Sodium Bicarbonate, Carageenan, Guar Gum
            <br></br>
            <br></br>
            <span className="font-bold">Contains:</span> Milk
          </p>
        </div>
        {/* Nutritional Information */}
        <div className="mb-4 md:hidden">
          <h2 className="text-xl font-semibold text-black">NUTRITIONAL</h2>
          <div className="h-1 my-2 bg-yellow-300"></div>
          <div className="grid grid-cols-2 gap-1">
            <div className="font-semibold text-black">Serving Size</div>
            <div className="text-right text-black">1 Carton (200ml)</div>
            <div className="font-semibold text-black">Calories</div>
            <div className="text-right text-black">160</div>
            <div className="font-semibold text-black">Total Fat</div>
            <div className="text-right text-black">4 g</div>
            <div className="font-semibold text-black">Saturated Fat</div>
            <div className="text-right text-black">2.5 g</div>
            <div className="font-semibold text-black">Trans Fat</div>
            <div className="text-right text-black">0 g</div>
            <div className="font-semibold text-black">Cholesterol</div>
            <div className="text-right text-black">10 mg</div>
            <div className="font-semibold text-black">Sodium</div>
            <div className="text-right text-black">60 mg</div>
            <div className="font-semibold text-black">Total Carbohydrate</div>
            <div className="text-right text-black">22 g</div>
            <div className="font-semibold text-black">Sugars</div>
            <div className="text-right text-black">15 g</div>
            <div className="font-semibold text-black">Protein</div>
            <div className="text-right text-black">3 g</div>
            <div className="font-semibold text-black">Vitamin D</div>
            <div className="text-right text-black">0 g</div>
            <div className="font-semibold text-black">Potassium</div>
            <div className="text-right text-black">200 mg</div>
            <div className="font-semibold text-black">Calcium</div>
            <div className="text-right text-black">100 mg</div>
            <p className="font-semibold text-black">Iron</p>
              <p className="text-right text-black">0 mg</p>
          </div>
        </div>

        <div className="hidden md:block">
          <h2 className="text-xl font-semibold text-black">NUTRITIONAL</h2>
          <div className="h-1 my-2 bg-yellow-300"></div>
          <div className="grid grid-cols-4 gap-1">
            <div>
              <div className="font-semibold text-black">Serving Size</div>
              <div className="font-semibold text-black">Calories</div>
              <div className="font-semibold text-black">Total Fat</div>
              <div className="font-semibold text-black">Saturated Fat</div>
              <div className="font-semibold text-black">Trans Fat</div>
              <div className="font-semibold text-black">Cholesterol</div>
              <div className="font-semibold text-black">Sodium</div>
            </div>
            <div className="md:mr-5 lg:mr-20">
              <div className="text-right text-black">1 Carton (200ml)</div>
              <div className="text-right text-black">160</div>
              <div className="text-right text-black">4 g</div>
              <div className="text-right text-black">2.5 g</div>
              <div className="text-right text-black">0 g</div>
              <div className="text-right text-black">10 mg</div>
              <div className="text-right text-black">60 mg</div>
            </div>
            <div className="md:ml-5 lg:mr-20">
              <div className="font-semibold text-black">Total Carbohydrate</div>
              <div className="font-semibold text-black">Sugars</div>
              <div className="font-semibold text-black">Protein</div>
              <div className="font-semibold text-black">Vitamin D</div>
              <div className="font-semibold text-black">Potassium</div>
              <div className="font-semibold text-black">Calcium</div>
              <div className="font-semibold text-black">Iron</div>
            </div>
            <div>
              <div className="text-right text-black">22 g</div>
              <div className="text-right text-black">15 g</div>
              <div className="text-right text-black">3 g</div>
              <div className="text-right text-black">0 g</div>
              <div className="text-right text-black">200 mg</div>
              <div className="text-right text-black">100 mg</div>
              <div className="text-right text-black">0 mg</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CoffeeMilkNutritionalInfo;
