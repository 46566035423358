import React, { useEffect } from "react";
import ImageSlider from "../components/ImageSlider";
import Image4 from "../assets/homepage/Croppedslider4.jpg";
import Image6 from "../assets/homepage/Croppedslider6.jpg";
import HeaderImage from "../components/HeaderImage";
import Sponsorship from "../components/Sponsorship";
import AtBinggraeLanding from "../components/AtBinggraeLanding";
import Products from "../components/Products";
import { Link } from "react-router-dom";
import StoreScrollBar from "../components/StoreScrollBar.jsx";

function Landing() {
  const images = [Image4, Image6];

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  useEffect(() => {
    // Add an event listener to scroll to top when the "Discover More" button is clicked
    const discoverMoreButton = document.querySelector(".discover-more-button");
    if (discoverMoreButton) {
      discoverMoreButton.addEventListener("click", scrollToTop);
    }

    // Clean up the event listener when the component unmounts
    return () => {
      if (discoverMoreButton) {
        discoverMoreButton.removeEventListener("click", scrollToTop);
      }
    };
  }, []);

  return (
    <React.Fragment>
      <HeaderImage />
      <Products />
      <ImageSlider images={images} intervalTime={3000} />
      <div className="xl:mt-20 mb-20">
        <AtBinggraeLanding />
      </div>
      <Sponsorship />
      <div className="mb-10">
        <StoreScrollBar />
      </div>
      <div className="flex items-center justify-center">
        <Link
          to="/BingStory"
          className="px-10 py-4 mb-10 font-semibold text-white transition duration-300 discover-more-button bg-red-500 rounded-full font-Koulen discover-more-button hover:bg-red-600 md:text-lg md:py-5 md:px-12 lg:py-5 lg:px-12 xl:py-6 xl:px-14"
        >
          Discover More
        </Link>
      </div>
    </React.Fragment>
  );
}

export default Landing;
