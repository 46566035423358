import React from "react";
import MelonMilk from "../../assets/MelonaMilkNutritionImages/MelonMilkIcon.png";

function MelonMilkNutritionalInfo() {
  return (
    <div className="flex flex-col items-center justify-center pb-20 mb-10 px-4 sm:px-8 -mt-10 md:px-16 z-70">
      {/* Set a maximum width for the component */}
      <div className="w-full max-w-screen-xl mx-auto rounded-lg">
        <div className="flex flex-col md:flex-row items-center justify-center">
          {/* MelonMilk Image */}
          <div className="">
            <img
              src={MelonMilk}
              alt="Food Product"
              className="h-auto w-64 ml-6 md:mr-52 -ml-10 lg:-ml-0"
            />
          </div>
          {/* Description */}
          <div className="">
            <h2 className="text-xl font-semibold text-black ">DESCRIPTION</h2>
            <div className="h-1 bg-green-600  my-2 "></div>
            <p className="text-black text-lg mb-4 ">
              Experience the essence of summer in a sip with our Melon Flavoured
              Milk. Indulge in the captivating flavor of ripe melons,
              beautifully blended with the creamy goodness of milk. Close your
              eyes and let the taste transport you to sunny orchards, where
              every sip is a taste of pure, juicy satisfaction.
            </p>
          </div>
        </div>
        {/* Ingredients */}
        <div className="mb-4">
          <h2 className="text-xl font-semibold text-black">INGREDIENTS</h2>
          <div className="h-1 bg-green-600  my-2"></div>
          <p className="text-black text-lg mb-10">
            Milk, Water, Sugar, Skim Milk Powder, Whey Powder, Melon Juice
            Concentrate, Artificial Flavor, Emulsifier(Mono-and Diglycerides),
            Stabilizer(Carrageenan, Guar Gum), Colour(Tartrazine, Brilliant Blue
            FCF)
            <br></br>
            <br></br>
            <span className="font-bold">Contains:</span> Milk
          </p>
        </div>
        {/* Nutritional Information */}
        <div className="mb-4 md:hidden">
          <h2 className="text-xl font-semibold text-black">NUTRITIONAL</h2>
          <div className="h-1 bg-green-600  my-2"></div>
          <div className="grid grid-cols-2 gap-1">
            <div className="font-semibold text-black">Serving Size</div>
            <div className="text-right text-black">1 Carton (200ml)</div>
            <div className="font-semibold text-black">Calories</div>
            <div className="text-right text-black">160</div>
            <div className="font-semibold text-black">Total Fat</div>
            <div className="text-right text-black">6 g</div>
            <div className="font-semibold text-black">Saturated Fat</div>
            <div className="text-right text-black">3.5 g</div>
            <div className="font-semibold text-black">Trans Fat</div>
            <div className="text-right text-black">0 g</div>
            <div className="font-semibold text-black">Cholesterol</div>
            <div className="text-right text-black">20 mg</div>
            <div className="font-semibold text-black">Sodium</div>
            <div className="text-right text-black">85 mg</div>
            <div className="font-semibold text-black">Total Carbohydrate</div>
            <div className="text-right text-black">22 g</div>
            <div className="font-semibold text-black">Sugars</div>
            <div className="text-right text-black">21 g</div>
            <div className="font-semibold text-black">Protein</div>
            <div className="text-right text-black">5 g</div>
            <div className="font-semibold text-black">Vitamin D</div>
            <div className="text-right text-black">0 g</div>
            <div className="font-semibold text-black">Potassium</div>
            <div className="text-right text-black">230 mg</div>
            <div className="font-semibold text-black">Calcium</div>
            <div className="text-right text-black">180 mg</div>
            <p className="font-semibold text-black">Iron</p>
            <p className="text-right text-black">0 mg</p>
          </div>
        </div>

        <div className="hidden md:block">
          <h2 className="text-xl font-semibold text-black">NUTRITIONAL</h2>
          <div className="h-1 bg-green-600 my-2"></div>
          <div className="grid grid-cols-4 gap-1">
            <div>
              <div className="font-semibold text-black">Serving Size</div>
              <div className="font-semibold text-black">Calories</div>
              <div className="font-semibold text-black">Total Fat</div>
              <div className="font-semibold text-black">Saturated Fat</div>
              <div className="font-semibold text-black">Trans Fat</div>
              <div className="font-semibold text-black">Cholesterol</div>
              <div className="font-semibold text-black">Sodium</div>
            </div>
            <div className="md:mr-5 lg:mr-20">
              <div className="text-right text-black">1 Carton (200ml)</div>
              <div className="text-right text-black">160</div>
              <div className="text-right text-black">6 g</div>
              <div className="text-right text-black">3.5 g</div>
              <div className="text-right text-black">0 g</div>
              <div className="text-right text-black">20 mg</div>
              <div className="text-right text-black">85 mg</div>
            </div>
            <div className="md:ml-5 lg:mr-20">
              <div className="font-semibold text-black">Total Carbohydrate</div>
              <div className="font-semibold text-black">Sugars</div>
              <div className="font-semibold text-black">Protein</div>
              <div className="font-semibold text-black">Vitamin D</div>
              <div className="font-semibold text-black">Potassium</div>
              <div className="font-semibold text-black">Calcium</div>
              <div className="font-semibold text-black">Iron</div>
            </div>
            <div>
              <div className="text-right text-black">22 g</div>
              <div className="text-right text-black">21 g</div>
              <div className="text-right text-black">5 g</div>
              <div className="text-right text-black">0 g</div>
              <div className="text-right text-black">230 mg</div>
              <div className="text-right text-black">180 mg</div>
              <div className="text-right text-black">0 mg</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MelonMilkNutritionalInfo;
