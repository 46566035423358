import React from "react";
import PowerCapWaterMelon from "../../assets/PowerCapProductPage/Powercap Watermelon.png";

const WaterMelonPowerCapNutrition = () => {
  return (
    <div className="flex flex-col items-center justify-center px-4 pb-10 mb-10 sm:px-8 md:px-16 z-3">
      <div className="w-full max-w-screen-xl mx-auto rounded-lg">
        <div className="flex flex-col items-center justify-center md:flex-row">
          <img
            src={PowerCapWaterMelon}
            alt="Food Product"
            className="w-16 w-24 h-auto mb-10 w-15 md:mr-24"
          />
          <div className="mb-4">
            <h2 className="text-xl font-semibold text-black">DESCRIPTION</h2>
            <div className="h-1 my-2 bg-red-200"></div>
            <p className="text-lg text-black">
              Dive into the ultimate frozen dessert with our Watermelon SodaCap. This flavour is a delightful blend of juicy watermelon goodness and the fizzy excitement of soda. Imagine biting into a slice of perfectly ripe watermelon while sipping on the carbonated magic of your favorite soda. It's a symphony of flavors that transports you to sun-soaked days and carefree laughter, one frozen bite at a time.
            </p>
          </div>
        </div>
        <div className="mb-4">
          <h2 className="text-xl font-semibold text-black">INGREDIENTS</h2>
          <div className="h-1 my-2 bg-red-200"></div>
          <p className="text-lg text-black">
          Water, Sugar, High Fructose Corn Syrup, Corn Syrup, Melon Syrup (Melon, Sugar, Water, Lemon Concentrate, Xanthan Gum, Carrageenan, Locust Bean Gum), Coconut Oil, Artificial Flavors, Mono- and Diglycerides, Citric Acid, Locust Bean Gum, Guar Gum, Salt, Cellulose Gum, Carrageenan, Red 40 (Allura Red), Yellow 5 (Tartrazine)
          </p>
          
        </div>
        <div className="mb-4 md:hidden">
          <h2 className="text-xl font-semibold text-black">NUTRITIONAL</h2>
          <div className="h-1 my-2 bg-red-200"></div>
          <div className="grid grid-cols-2 gap-1">
            <p className="font-semibold text-black">Serving Size</p>
            <p className="text-right text-black">1 Tube (130ml)</p>
            <p className="font-semibold text-black">Calories</p>
            <p className="text-right text-black">120</p>
            <p className="font-semibold text-black">Total Fat</p>
            <p className="text-right text-black">0.5 g</p>
            <p className="font-semibold text-black">Saturated Fat</p>
            <p className="text-right text-black">0.5 g</p>
            <p className="font-semibold text-black">Trans Fat</p>
            <p className="text-right text-black">0 g</p>
            <p className="font-semibold text-black">Cholesterol</p>
            <p className="text-right text-black">0 mg</p>
            <p className="font-semibold text-black">Sodium</p>
            <p className="text-right text-black">25 mg</p>
            <p className="font-semibold text-black">Total Carbohydrate</p>
            <p className="text-right text-black">28 g</p>
            <p className="font-semibold text-black">Sugars</p>
            <p className="text-right text-black">24 g</p>
            <p className="font-semibold text-black">Protein</p>
            <p className="text-right text-black">0 g</p>
            <p className="font-semibold text-black">Vitamin D</p>
            <p className="text-right text-black">0 g</p>
            <p className="font-semibold text-black">Potassium</p>
            <p className="text-right text-black">0 mg</p>
            <p className="font-semibold text-black">Calcium</p>
            <p className="text-right text-black">0 mg</p>
            <p className="font-semibold text-black">Iron</p>
            <p className="text-right text-black">0 mg</p>
          </div>
        </div>

        <div className="hidden md:block">
  <h2 className="text-xl font-semibold text-black">NUTRITIONAL</h2>
  <div className="h-1 my-2 bg-red-200"></div>
  <div className="grid grid-cols-4 gap-1">
    <div>
      <p className="font-semibold text-black">Serving Size</p>
      <p className="font-semibold text-black">Calories</p>
      <p className="font-semibold text-black">Total Fat</p>
      <p className="font-semibold text-black">Saturated Fat</p>
      <p className="font-semibold text-black">Trans Fat</p>
      <p className="font-semibold text-black">Cholesterol</p>
      <p className="font-semibold text-black">Sodium</p>
    </div>
    <div className="md:mr-5 lg:mr-20">
      <p className="text-right text-black">1 Tube (130ml)</p>
      <p className="text-right text-black">120</p>
      <p className="text-right text-black">0.5 g</p>
      <p className="text-right text-black">0.5 g</p>
      <p className="text-right text-black">0 g</p>
      <p className="text-right text-black">0 mg</p>
      <p className="text-right text-black">25 mg</p>
    </div>
    <div className="md:ml-5 lg:mr-20">
      <p className="font-semibold text-black">Total Carbohydrate</p>
      <p className="font-semibold text-black">Sugars</p>
      <p className="font-semibold text-black">Protein</p>
      <p className="font-semibold text-black">Vitamin D</p>
      <p className="font-semibold text-black">Potassium</p>
      <p className="font-semibold text-black">Calcium</p>
      <p className="font-semibold text-black">Iron</p>
    </div>
    <div>
      <p className="text-right text-black">28 g</p>
      <p className="text-right text-black">24 g</p>
      <p className="text-right text-black">0 g</p>
      <p className="text-right text-black">0 g</p>
      <p className="text-right text-black">0 mg</p>
      <p className="text-right text-black">0 mg</p>
      <p className="text-right text-black">0 mg</p>

    </div>
  </div>
</div>


      </div>
    </div>
  );
};

export default WaterMelonPowerCapNutrition;
