import React, { useState } from "react";
import SodaPowerCap from "../assets/PowerCapProductPage/SodaPowerCap.jpg";
import WaterMelonPowerCap from "../assets/PowerCapProductPage/WaterMelonPowerCap.jpg";
import SodaIcon from "../assets/PowerCapProductPage/PowerCapIcon/SodaPowerCapIcon.png";
import WatermelonIcon from "../assets/PowerCapProductPage/PowerCapIcon/WaterMelonPowerCapIcon.png";
import WaterMelonPowerCapNutrition from "../components/nutritionalInfoPowerCap/WaterMelonPowerCapNutrition";
import SodaPowerCapNutrition from "../components/nutritionalInfoPowerCap/SodaPowerCapNutrition";
import {
  LazyLoadComponent,
  LazyLoadImage,
} from "react-lazy-load-image-component";

function PowerCapProductPage() {
  const PowerCapFlavours = [
    {
      image: SodaPowerCap,
      icon: SodaIcon,
      nutrition: <SodaPowerCapNutrition />,
      backgroundColour: "#e0f2fe", // Add background color for Soda flavor
    },
    {
      image: WaterMelonPowerCap,
      icon: WatermelonIcon,
      nutrition: <WaterMelonPowerCapNutrition />,
      backgroundColour: "#fee2e2", // Add background color for Watermelon flavor
    },
  ];

  const [currentFlavorIndex, setCurrentFlavorIndex] = useState(0);

  const currentFlavor = PowerCapFlavours[currentFlavorIndex];

  const firstRowIcons = [SodaIcon, WatermelonIcon];

  return (
    <React.Fragment>
       <div
        
        style={{ backgroundColor: currentFlavor.backgroundColour }}
      >
        <div>
          <div className="pt-20">
            <div className="z-3">
              <LazyLoadImage
                src={currentFlavor.image}
                alt="Melona Product"
                className="w-full object-cover rounded-lg md:rounded-xl lg:h-[110vh]"
              />

              <div className="flex justify-center mt-4">
                {firstRowIcons.map((icon, index) => (
                  <button
                    key={index}
                    className={`mx-2 ${
                      currentFlavorIndex === index
                        ? "transform scale-110"
                        : "transition-transform hover:scale-110"
                    }`}
                    onClick={() => setCurrentFlavorIndex(index)}
                  >
                    <LazyLoadImage
                      src={icon}
                      alt={`PowerCap Flavor Icon ${index}`}
                      className="w-20 h-20 animate-bounce"
                    />
                  </button>
                ))}
              </div>
            </div>
          </div>
          <LazyLoadComponent>
            <div className="mb-8 md:mb-16 lg:mb-20 -z-4">
              {currentFlavor.nutrition}
            </div>
          </LazyLoadComponent>
        </div>
      </div>
    </React.Fragment>
  );
}

export default PowerCapProductPage;
