import React from "react";
import melonaboxtower from "../assets/products/Melonas/Melonas1.jpg";
import {
  LazyLoadComponent,
  LazyLoadImage,
} from "react-lazy-load-image-component";

function AtBinggraeLanding() {
  return (
    <React.Fragment>
      <LazyLoadComponent>
        <div className="flex items-center justify-center">
          <div className="z-10 max-w-screen-xl mt-10 h-fit">
            <div className="px-6 2xl:mx-auto 2xl:container 2xl:px-20 ">
              <div className="flex flex-col-reverse items-center justify-center md:flex-row">
                <div className="flex flex-col items-center justify-center md:py-20 xl:w-1/2 sm:w-1/2 lg:mr-20 md:mr-6 md:items-end xl:mr-28 max-h-[80vh]">
                  <div className="flex flex-col items-center justify-center">
                    <h1 className="text-2xl font-bold text-center text-gray-800 md:text-3xl">
                      AT BINGGRAE WE ARE COMMITTED TO
                    </h1>
                    <div className="flex flex-col items-center mt-12 md:mt-14">
                      <div
                        className="flex items-center justify-center w-16 h-16 hover:scale-110"
                        role="img"
                        aria-label="fruitbowl"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="100"
                          height="100"
                          viewBox="0 0 24 24"
                        >
                          <path
                            fill="none"
                            stroke="currentColor"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="1.5"
                            d="M16.5 18.75h-9m9 0a3 3 0 0 1 3 3h-15a3 3 0 0 1 3-3m9 0v-3.375c0-.621-.503-1.125-1.125-1.125h-.871M7.5 18.75v-3.375c0-.621.504-1.125 1.125-1.125h.872m5.007 0H9.497m5.007 0a7.454 7.454 0 0 1-.982-3.172M9.497 14.25a7.454 7.454 0 0 0 .981-3.172M5.25 4.236c-.982.143-1.954.317-2.916.52A6.003 6.003 0 0 0 7.73 9.728M5.25 4.236V4.5c0 2.108.966 3.99 2.48 5.228M5.25 4.236V2.721A48.339 48.339 0 0 1 12 2.25c2.291 0 4.545.16 6.75.47v1.516M7.73 9.728a6.726 6.726 0 0 0 2.748 1.35m8.272-6.842V4.5c0 2.108-.966 3.99-2.48 5.228m2.48-5.492a46.32 46.32 0 0 1 2.916.52a6.003 6.003 0 0 1-5.395 4.972m0 0a6.726 6.726 0 0 1-2.749 1.35m0 0a6.772 6.772 0 0 1-3.044 0"
                          />
                        </svg>
                      </div>
                      <p className="w-full mt-6 mb-6 text-lg leading-6 text-center text-gray-600 xl:text-lg sm:w-96">
                        Providing Our Community with High Quality Products
                      </p>
                    </div>
                    <div className="flex flex-col items-center mt-7">
                      <div
                        className="flex items-center justify-center w-16 h-16 rounded-full hover:scale-110"
                        role="img"
                        aria-label="sugar"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="100"
                          height="100"
                          viewBox="0 0 24 24"
                        >
                          <path
                            fill="currentColor"
                            d="M17.9 17.39c-.26-.8-1.01-1.39-1.9-1.39h-1v-3a1 1 0 0 0-1-1H8v-2h2a1 1 0 0 0 1-1V7h2a2 2 0 0 0 2-2v-.41a7.984 7.984 0 0 1 2.9 12.8M11 19.93c-3.95-.49-7-3.85-7-7.93c0-.62.08-1.22.21-1.79L9 15v1a2 2 0 0 0 2 2m1-16A10 10 0 0 0 2 12a10 10 0 0 0 10 10a10 10 0 0 0 10-10A10 10 0 0 0 12 2Z"
                          />
                        </svg>
                      </div>
                      <p className="w-full mt-6 mb-6 text-lg leading-6 text-center text-gray-600 xl:text-m sm:w-96">
                        Supplying Amazing Products To Customers Worldwide
                      </p>
                    </div>
                    <div className="flex flex-col items-center mt-7">
                      <div
                        className="flex items-center justify-center w-16 h-16 rounded-full hover:scale-110"
                        role="img"
                        aria-label="melona bar"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="100"
                          height="100"
                          viewBox="0 0 24 24"
                        >
                          <rect
                            x="0"
                            y="0"
                            width="24"
                            height="24"
                            fill="none"
                            stroke="none"
                          />
                          <path
                            fill="currentColor"
                            d="M20 17q.86 0 1.45.6t.58 1.4L14 22l-7-2v-9h1.95l7.27 2.69q.78.31.78 1.12q0 .47-.34.82t-.86.37H13l-1.75-.67l-.33.94L13 17h7M16 3.23Q17.06 2 18.7 2q1.36 0 2.3 1t1 2.3q0 1.03-1 2.46t-1.97 2.39T16 13q-2.08-1.89-3.06-2.85t-1.97-2.39T10 5.3q0-1.36.97-2.3t2.34-1q1.6 0 2.69 1.23M.984 11H5v11H.984V11Z"
                          />
                        </svg>
                      </div>
                      <p className="w-full mt-6 text-lg leading-6 text-center text-gray-600 xl:text-lg sm:w-96">
                        Bringing A Smile To Anyone Who Enjoys Our Products
                      </p>
                    </div>
                  </div>
                </div>

                <div className="items-center justify-center sm:flex ">
                  <LazyLoadImage
                    src={melonaboxtower}
                    alt="Melona Tower"
                    className="hidden md:block h-full max-h-[90vh] max-w-full lg:block rounded-2xl object-cover object-center"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </LazyLoadComponent>
    </React.Fragment>
  );
}

export default AtBinggraeLanding;
