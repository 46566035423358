import React from "react";
import HeaderPhoto from "../assets/homepage/HeaderImage.png";

function HeaderImage() {
  return (
    <div className="pt-16 -z-1">
      <div className="w-full">
        <img
          src={HeaderPhoto}
          alt="Header Banner"
          className="w-screen max-h-[70vh] object-cover xl:max-h-xl"
        />
      </div>
    </div>
  );
}

export default HeaderImage;
