import React from "react";
import laptop from "../assets/misc/laptop.jpg";
import building from "../assets/misc/building.jpg";
import nature from "../assets/misc/nature.jpg";
import binggraehq from "../assets/misc/BinggraeHQ.jpg";
import {
  LazyLoadComponent,
  LazyLoadImage,
} from "react-lazy-load-image-component";

function BinggraeStory() {
  return (
    <React.Fragment>
      <div className="sm: pt-24 md:mt-5 mb-1.5 pt-16">
        <h2 className="text-3xl font-bold text-center md:text-4xl lg:text-5xl ">
          Management Philosophy
        </h2>
        <p className="pt-4 mb-4 text-xl text-center text-red-600 md:text-2xl md:mb-8">
          For the health and happiness of people, Binggrae makes the best
          customer value.
        </p>

        <div className="flex flex-col justify-center md:flex-row md:space-x-20">
          <div className="p-2 md:p-4">
            <div className="max-w-sm overflow-hidden shadow-lg rounded-xl">
              <LazyLoadImage
                className="object-cover w-full h-32 md:h-48"
                src={laptop}
                alt="Card"
              />
              <div className="px-6 py-4 rounded-lg">
                <div className="mb-2 text-xl font-bold md:text-3xl">
                  Mission
                </div>
                <p className="text-base text-gray-700 md:text-lg">
                  Our paramount mission is to earnestly and passionately spread
                  health and happiness, ensuring that we bring radiant smiles
                  and joy to each and every individual we touch.
                </p>
              </div>
            </div>
          </div>
          <div className="p-2 md:p-4">
            <div className="max-w-sm overflow-hidden rounded shadow-lg">
              <LazyLoadImage
                className="object-cover w-full h-32 md:h-48"
                src={building}
                alt="Building"
              />
              <div className="px-6 py-4 rounded-lg">
                <div className="mb-2 text-xl font-bold md:text-3xl">Vision</div>
                <p className="text-base text-gray-700 md:text-lg">
                  We aspire to establish ourselves as a distinguished global
                  leader that diligently creates unparalleled customer value and
                  actively contributes to making the world a better and more
                  harmonious place.
                </p>
              </div>
            </div>
          </div>
          <div className="p-2 md:p-4">
            <div className="max-w-sm overflow-hidden rounded shadow-lg">
              <LazyLoadImage
                className="object-cover w-full h-32 md:h-48"
                src={nature}
                alt="Nature"
              />
              <div className="px-6 py-4 rounded-lg">
                <div className="mb-2 text-xl font-bold md:text-3xl">
                  Sustainability
                </div>
                <p className="text-base text-gray-700 md:text-lg">
                  We aim to lead the preservation of the global environment for
                  future generations, creating social value, and realizing
                  transparent governance that meets the highest global
                  standards.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <LazyLoadComponent>
        <div className="pb-20">
          <h1 className="py-10 pt-20 text-3xl font-bold text-center md:text-4xl lg:text-5xl">
            Fundamental Priorities
          </h1>
          <div className="flex justify-center">
            <div className="grid grid-cols-1 lg:grid-cols-3 md:grid-cols-2 lg:gap-8 md:gap-12 gap-14 ">
              {/* Customers grid Card */}
              <div className="flex flex-col items-center text-center">
                <svg
                  className="hover:scale-110"
                  xmlns="http://www.w3.org/2000/svg"
                  width="56"
                  height="56"
                  viewBox="0 0 16 16"
                >
                  <rect
                    x="0"
                    y="0"
                    width="16"
                    height="16"
                    fill="none"
                    stroke="none"
                  />
                  <path
                    fill="currentColor"
                    d="M8 8a3 3 0 1 0 0-6a3 3 0 0 0 0 6Zm2-3a2 2 0 1 1-4 0a2 2 0 0 1 4 0Zm4 8c0 1-1 1-1 1H3s-1 0-1-1s1-4 6-4s6 3 6 4Zm-1-.004c-.001-.246-.154-.986-.832-1.664C11.516 10.68 10.289 10 8 10c-2.29 0-3.516.68-4.168 1.332c-.678.678-.83 1.418-.832 1.664h10Z"
                  />
                </svg>
                <h3 className="mt-8 text-3xl font-semibold leading-5 text-gray-800 lg:mt-10">
                  Customers
                </h3>
                <p className="w-full mt-4 text-lg font-normal leading-6 text-gray-600 lg:w-full md:w-9/12">
                  Providing the best value for our customers
                </p>
              </div>

              {/* Safety Grid Card */}

              <div className="flex flex-col items-center text-center">
                <svg
                  className="hover:scale-110"
                  xmlns="http://www.w3.org/2000/svg"
                  width="56"
                  height="56"
                  viewBox="0 0 1024 1024"
                >
                  <rect
                    x="0"
                    y="0"
                    width="1024"
                    height="1024"
                    fill="none"
                    stroke="none"
                  />
                  <path
                    fill="currentColor"
                    d="M866.9 169.9L527.1 54.1C523 52.7 517.5 52 512 52s-11 .7-15.1 2.1L157.1 169.9c-8.3 2.8-15.1 12.4-15.1 21.2v482.4c0 8.8 5.7 20.4 12.6 25.9L499.3 968c3.5 2.7 8 4.1 12.6 4.1s9.2-1.4 12.6-4.1l344.7-268.6c6.9-5.4 12.6-17 12.6-25.9V191.1c.2-8.8-6.6-18.3-14.9-21.2zM810 654.3L512 886.5L214 654.3V226.7l298-101.6l298 101.6v427.6zm-405.8-201c-3-4.1-7.8-6.6-13-6.6H336c-6.5 0-10.3 7.4-6.5 12.7l126.4 174a16.1 16.1 0 0 0 26 0l212.6-292.7c3.8-5.3 0-12.7-6.5-12.7h-55.2c-5.1 0-10 2.5-13 6.6L468.9 542.4l-64.7-89.1z"
                  />
                </svg>
                <h3 className="mt-8 text-3xl font-semibold leading-5 text-gray-800 lg:mt-10">
                  Safety
                </h3>
                <p className="w-full mt-4 text-lg font-normal leading-6 text-gray-600 lg:w-full md:w-9/12">
                  Having the safety-first mindset in all ventures
                </p>
              </div>

              {/* Quality Grid Card */}

              <div className="flex flex-col items-center text-center">
                <svg
                  className="hover:scale-110"
                  xmlns="http://www.w3.org/2000/svg"
                  width="56"
                  height="56"
                  viewBox="0 0 24 24"
                >
                  <rect
                    x="0"
                    y="0"
                    width="24"
                    height="24"
                    fill="none"
                    stroke="none"
                  />
                  <path
                    fill="none"
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="1.5"
                    d="M16.5 18.75h-9m9 0a3 3 0 0 1 3 3h-15a3 3 0 0 1 3-3m9 0v-3.375c0-.621-.503-1.125-1.125-1.125h-.871M7.5 18.75v-3.375c0-.621.504-1.125 1.125-1.125h.872m5.007 0H9.497m5.007 0a7.454 7.454 0 0 1-.982-3.172M9.497 14.25a7.454 7.454 0 0 0 .981-3.172M5.25 4.236c-.982.143-1.954.317-2.916.52A6.003 6.003 0 0 0 7.73 9.728M5.25 4.236V4.5c0 2.108.966 3.99 2.48 5.228M5.25 4.236V2.721A48.339 48.339 0 0 1 12 2.25c2.291 0 4.545.16 6.75.47v1.516M7.73 9.728a6.726 6.726 0 0 0 2.748 1.35m8.272-6.842V4.5c0 2.108-.966 3.99-2.48 5.228m2.48-5.492a46.32 46.32 0 0 1 2.916.52a6.003 6.003 0 0 1-5.395 4.972m0 0a6.726 6.726 0 0 1-2.749 1.35m0 0a6.772 6.772 0 0 1-3.044 0"
                  />
                </svg>
                <h3 className="mt-8 text-3xl font-semibold leading-5 text-gray-800 lg:mt-10">
                  Quality
                </h3>
                <p className="w-full mt-4 text-lg font-normal leading-6 text-gray-600 lg:w-full md:w-9/12">
                  Ensuring the best quality in all products
                </p>
              </div>

              {/* Organizational Culture Card */}

              <div className="flex flex-col items-center text-center">
                <svg
                  className="hover:scale-110"
                  xmlns="http://www.w3.org/2000/svg"
                  width="56"
                  height="56"
                  viewBox="0 0 1024 1024"
                >
                  <rect
                    x="0"
                    y="0"
                    width="1024"
                    height="1024"
                    fill="none"
                    stroke="none"
                  />
                  <path
                    fill="currentColor"
                    d="M192 128v704h384V128H192zm-32-64h448a32 32 0 0 1 32 32v768a32 32 0 0 1-32 32H160a32 32 0 0 1-32-32V96a32 32 0 0 1 32-32z"
                  />
                  <path
                    fill="currentColor"
                    d="M256 256h256v64H256v-64zm0 192h256v64H256v-64zm0 192h256v64H256v-64zm384-128h128v64H640v-64zm0 128h128v64H640v-64zM64 832h896v64H64v-64z"
                  />
                  <path
                    fill="currentColor"
                    d="M640 384v448h192V384H640zm-32-64h256a32 32 0 0 1 32 32v512a32 32 0 0 1-32 32H608a32 32 0 0 1-32-32V352a32 32 0 0 1 32-32z"
                  />
                </svg>
                <h3 className="mt-8 text-3xl font-semibold leading-5 text-gray-800 lg:mt-10">
                  Organizational Culture
                </h3>
                <p className="w-full mt-4 text-lg font-normal leading-6 text-gray-600 lg:w-full md:w-9/12">
                  Trusting in our peers and advocating for innovation
                </p>
              </div>

              {/* Social Contribution Card */}
              <div className="flex flex-col items-center text-center">
                <svg
                  className="hover:scale-110"
                  xmlns="http://www.w3.org/2000/svg"
                  width="56"
                  height="56"
                  viewBox="0 0 48 48"
                >
                  <rect
                    x="0"
                    y="0"
                    width="48"
                    height="48"
                    fill="none"
                    stroke="none"
                  />
                  <g fill="currentColor">
                    <path d="M32.773 36.007c.388-.533.801-.95 1.217-1.372c.547-.555 1.1-1.115 1.61-1.95c.128-.21.258-.41.384-.606c.455-.706.86-1.332.934-1.972c.096-.816.156-5.716-.108-6.458c-.265-.741-1.79-1.157-2.317.503c-.26.819-.342 1.872-.416 2.828c-.077.98-.145 1.858-.39 2.272c-.483.818-2.038 2.853-2.038 2.853l1.342-2.547s.204-.352.097-1.021c-.108-.67-.917-1.606-1.297-.862c-.38.743-1.045 1.414-1.045 1.414l-2.348 2.829s-.765.992-.786 1.536c-.007.182-.12.586-.25 1.043c-.145.516-.31 1.1-.362 1.51V37h5.773v-.993Zm-18.596 0c-.389-.533-.803-.95-1.22-1.372c-.55-.555-1.104-1.115-1.616-1.95c-.128-.21-.259-.411-.385-.607c-.457-.705-.862-1.33-.938-1.97c-.096-.817.203-5.717.468-6.459c.266-.741 1.796-1.157 2.325.503c.26.819.343 1.872.418 2.828c.076.98.145 1.858.39 2.272c.485.818 2.045 2.853 2.045 2.853l-1.346-2.547s-.205-.352-.097-1.021c.108-.67.92-1.606 1.301-.862c.382.743 1.049 1.414 1.049 1.414l2.355 2.829s.768.992.789 1.536c.015.387.187 1.78.285 2.553V37h-5.823v-.993ZM19.781 11C17.018 11 15 13.986 15 17.207C15 24.397 24 29 24 29s9-4.9 9-11.793C33 13.987 30.982 11 28.219 11c-1.918 0-3.34 1.34-4.219 3.24c-.878-1.9-2.301-3.24-4.219-3.24Z" />
                    <path
                      fillRule="evenodd"
                      d="M6 39a3 3 0 0 0 3 3h30a3 3 0 0 0 3-3V9a3 3 0 0 0-3-3H9a3 3 0 0 0-3 3v30ZM8 9a1 1 0 0 1 1-1h30a1 1 0 0 1 1 1v30a1 1 0 0 1-1 1h-6v-1a1 1 0 0 0-1-1h-4a1 1 0 0 0-1 1v1h-7v-1a1 1 0 0 0-1-1h-4a1 1 0 0 0-1 1v1H9a1 1 0 0 1-1-1V9Z"
                      clipRule="evenodd"
                    />
                  </g>
                </svg>
                <h3 className="mt-8 text-3xl font-semibold leading-5 text-gray-800 lg:mt-10">
                  Social Contribution
                </h3>
                <p className="w-full mt-4 text-lg font-normal leading-6 text-gray-600 lg:w-full md:w-9/12">
                  Supporting mutual coexistence and shared management.
                </p>
              </div>

              {/* Profit Grid Card */}
              <div className="flex flex-col items-center text-center">
                <svg
                  className="hover:scale-110"
                  xmlns="http://www.w3.org/2000/svg"
                  width="80"
                  height="56"
                  viewBox="0 0 32 15"
                  fill="none"
                >
                  <path
                    fill="currentColor"
                    d="M14.5 2a.5.5 0 0 0 0 1h1.793L11.5 7.793L8.854 5.146a.5.5 0 0 0-.708 0l-5 5a.5.5 0 0 0 .708.708L8.5 6.207l2.646 2.647a.5.5 0 0 0 .708 0L17 3.707V5.5a.5.5 0 0 0 1 0v-3a.5.5 0 0 0-.5-.5h-3Zm1 7a.5.5 0 0 0-.5.5v8a.5.5 0 0 0 1 0v-8a.5.5 0 0 0-.5-.5Zm-8 2a.5.5 0 0 0-.5.5v6a.5.5 0 0 0 1 0v-6a.5.5 0 0 0-.5-.5Zm3.5 2.5a.5.5 0 0 1 1 0v4a.5.5 0 0 1-1 0v-4Zm-8 1a.5.5 0 0 1 1 0v3a.5.5 0 0 1-1 0v-3Z"
                  />
                </svg>
                <h3 className="mt-8 text-3xl font-semibold leading-5 text-gray-800 lg:mt-10">
                  Profit
                </h3>
                <p className="w-full mt-4 text-lg font-normal leading-6 text-gray-600 lg:w-full md:w-9/12">
                  To promote the profit-based growth
                </p>
              </div>
            </div>
          </div>
        </div>
      </LazyLoadComponent>

      <LazyLoadComponent>
       
          <div className="container px-4 py-6 mx-auto lg:py-10 md:py-8">
            <div className="flex flex-col items-center justify-center lg:flex-row lg:gap-12">
              {/* Order 2 on small screens and order 1 on medium and larger screens */}
              <div className="flex flex-col justify-center w-full lg:w-5/12 lg:order-1 md:order-2">
                <h1 className="text-3xl font-bold leading-9 text-gray-800 md:pb-10 lg:text-4xl xl:text-5xl">
                  Binggrae Headquarters
                </h1>
                <p className="pb-8 font-normal leading-6 text-justify text-gray-600 md:pb-0 md:text-base md:mb-10 lg:text-lg xl:text-xl">
                  Located in Saemunan-ro, Jongno-gu (District), Seoul, the
                  Binggrae Headquarters stands as a prominent hub of innovation
                  and industry excellence. This iconic establishment serves as the
                  heart of Binggrae, a leading and well-respected company in the
                  global market. From this strategic location, Binggrae
                  orchestrates its dynamic operations, encompassing a diverse
                  range of exceptional products that have captured the hearts of
                  customers worldwide. The headquarters not only symbolize the
                  company's commitment to delivering quality and satisfaction but
                  also reflect its dedication to pushing boundaries and setting
                  new standards in the industry.
                </p>
              </div>
              <div className="md:order-2 sm:order-1">
                <LazyLoadImage
                  className="w-auto rounded-2xl max-h-[50vh]"
                  src={binggraehq}
                  alt="A group of People"
                />
              </div>
            </div>
          </div>

      </LazyLoadComponent>
    </React.Fragment>


  );
}

export default BinggraeStory;

/*
{ Order 1 on small screens and order 2 on medium and larger screens}
<div className="w-full mb-10 lg:w-7/12 lg:order-2 sm:order-1">
<LazyLoadImage
  className="w-full h-50 rounded-2xl"
  src={binggraehq}
  alt="A group of People"
/>
</div>
*/