import React from "react";

import melonbars from "../assets/products/MelonMelona/MelonMelona2.jpg";
import melonatower from "../assets/products/melonatower.jpg";
import { LazyLoadComponent } from "react-lazy-load-image-component";
import { LazyLoadImage } from "react-lazy-load-image-component";

function OurStory() {
  return (
    <React.Fragment>
      <LazyLoadComponent>
        <div className="container px-4 py-6 mx-auto lg:py-10 md:py-8">
          <div className="flex flex-col justify-center lg:flex-row pt-20 lg:gap-12">
            {/* Order 1 on small screens and order 2 on medium and larger screens */}
            <div className="w-full mb-10 lg:w-7/12 lg:order-1 sm:order-2">
              <LazyLoadImage
                className="w-full h-auto rounded-2xl"
                src={melonbars}
                alt="A group of People"
              />
            </div>
            {/* Order 2 on small screens and order 1 on medium and larger screens */}
            <div className="flex flex-col justify-center w-full lg:w-5/12 lg:order-1 md:order-2">
              <h1 className="pb-4 text-3xl font-bold leading-9 text-gray-800 lg:text-4xl xl:text-5xl">
                Our Story
              </h1>
              <p className="mb-10 text-base font-normal leading-6 text-gray-600 lg:text-lg xl:text-xl sm:">
                In 1992, at a time when imported fruits were still a rare
                commodity in South Korea, the leading food and beverage company
                Binggrae invented these iconic melon-flavored ice bars. Upon
                their release, Melona quickly took South Korea by storm,
                breaking records and selling more than $20.5 million CAD in its
                first year on the market. Today, Melona is still a mainstay in
                the market, with the creation of 5 additional flavors and more
                to come. It has settled down in the memories of consumers for
                many years and continues to be a sweet temptation in our daily
                lives.
              </p>
            </div>
          </div>
        </div>
      </LazyLoadComponent>

      <LazyLoadComponent>
        <div className="flex items-center justify-center h-fit">
          <div className="px-6 2xl:mx-auto 2xl:container 2xl:px-20 ">
            <div className="flex flex-col-reverse items-center justify-center md:flex-row">
              <div className="flex flex-col items-center justify-center md:py-16 xl:w-1/2 sm:w-1/2 lg:mr-20 md:mr-6 md:items-end xl:mr-28 h-max max-h-[80vh]">
                <div className="flex flex-col items-center justify-center">
                  <h1 className="text-2xl font-bold text-center text-gray-800 hidden md:block md:text-4xl">
                    Why Melona?
                  </h1>
                  <p className="w-full mt-2 text-lg leading-6 text-center text-gray-600 hidden md:block sm:w-96 lg:text-lg">
                    Reasons why customers choose Melona as their dessert of
                    choice
                  </p>
                  <div className="mt-4 hidden md:block md:mt-6 md:flex flex-col items-center">
                    <div
                      className="flex items-center justify-center w-16 h-16 bg-white rounded-full shadow hover:scale-110 -z-1 "
                      role="img"
                      aria-label="fruitbowl"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="40"
                        height="40"
                        viewBox="0 0 32 32"
                      >
                        <rect
                          x="0"
                          y="0"
                          width="32"
                          height="32"
                          fill="none"
                          stroke="none"
                        />
                        <path
                          fill="currentColor"
                          d="M26 15a6.003 6.003 0 0 0-3.107-5.253A3.98 3.98 0 0 0 24 7h-2a2.002 2.002 0 0 1-2 2a6.004 6.004 0 0 0-5.995 5.892A7 7 0 0 1 12 10a3.996 3.996 0 0 0-3-3.858V4H7v2.142A3.996 3.996 0 0 0 4 10v5H2v1a14 14 0 0 0 28 0v-1Zm-6-4a4.005 4.005 0 0 1 4 4h-8a4.005 4.005 0 0 1 4-4ZM6 10a2 2 0 1 1 4 0a8.991 8.991 0 0 0 1.532 5H6Zm10 18A12.017 12.017 0 0 1 4.041 17H27.96A12.017 12.017 0 0 1 16 28Z"
                        />
                      </svg>
                    </div>
                    <p className="w-full mt-6 mb-6 text-lg leading-6 text-center text-gray-600 xl:text-lg sm:w-96">
                      Smooth, light, and refreshing fruit flavours
                    </p>
                  </div>
                  <div className="mt-4 hidden md:block md:mt-6 md:flex flex-col items-center">
                    <div
                      className="flex items-center justify-center w-16 h-16 bg-white rounded-full shadow hover:scale-110"
                      role="img"
                      aria-label="sugar"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="40"
                        height="40"
                        viewBox="0 0 24 24"
                      >
                        <rect
                          x="0"
                          y="0"
                          width="24"
                          height="24"
                          fill="none"
                          stroke="none"
                        />
                        <path
                          fill="currentColor"
                          d="M21 16.5c0 .38-.21.71-.53.88l-7.9 4.44c-.16.12-.36.18-.57.18c-.21 0-.41-.06-.57-.18l-7.9-4.44A.991.991 0 0 1 3 16.5v-9c0-.38.21-.71.53-.88l7.9-4.44c.16-.12.36-.18.57-.18c.21 0 .41.06.57.18l7.9 4.44c.32.17.53.5.53.88v9M12 4.15L6.04 7.5L12 10.85l5.96-3.35L12 4.15M5 15.91l6 3.38v-6.71L5 9.21v6.7m14 0v-6.7l-6 3.37v6.71l6-3.38Z"
                        />
                      </svg>
                    </div>
                    <p className="w-full mt-6 mb-6 text-lg leading-6 text-center text-gray-600 xl:text-lg sm:w-96">
                      Perfect amount of sweetness that appeals to both children
                      and adults
                    </p>
                  </div>
                  <div className="mt-4 hidden md:block md:mt-6 md:flex flex-col items-center">
                    <div
                      className="flex items-center justify-center w-16 h-16 bg-white rounded-full shadow hover:scale-110"
                      role="img"
                      aria-label="melona bar"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="40"
                        height="40"
                        viewBox="0 0 1024 1024"
                      >
                        <rect
                          x="0"
                          y="0"
                          width="1024"
                          height="1024"
                          fill="none"
                          stroke="none"
                        />
                        <path
                          fill="currentColor"
                          d="M416 640h256a32 32 0 0 0 32-32V160a32 32 0 0 0-32-32H352a32 32 0 0 0-32 32v448a32 32 0 0 0 32 32h64zm192 64v160a96 96 0 0 1-192 0V704h-64a96 96 0 0 1-96-96V160a96 96 0 0 1 96-96h320a96 96 0 0 1 96 96v448a96 96 0 0 1-96 96h-64zm-64 0h-64v160a32 32 0 1 0 64 0V704z"
                        />
                      </svg>
                    </div>
                    <p className="w-full mt-6 text-lg leading-6 text-center text-gray-600 xl:text-lg sm:w-96">
                      Unique soft and chewy texture
                    </p>
                  </div>

                  <div className="mt-4 hidden md:block md:mt-6 md:flex flex-col items-center">
                    <div
                      className="flex items-center justify-center w-16 h-16 bg-white rounded-full shadow hover:scale-110"
                      role="img"
                      aria-label="recipe book"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="32"
                        height="32"
                        viewBox="0 0 32 32"
                      >
                        <rect
                          x="0"
                          y="0"
                          width="18"
                          height="18"
                          fill="none"
                          stroke="none"
                        />
                        <path
                          fill="none"
                          stroke="currentColor"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          d="M16 7S9 1 2 6v22c7-5 14 0 14 0s7-5 14 0V6c-7-5-14 1-14 1Zm0 0v21"
                        />
                      </svg>
                    </div>
                    <p className="w-full mt-6 mb-6 text-lg leading-6 text-center text-gray-600 xl:text-lg sm:w-96">
                      A versatile ingredient that is used to spice up
                      traditional recipes. Popular recipes include the Melona
                      Latte and Melona Cocktail{" "}
                    </p>
                  </div>
                </div>
              </div>

              <div className="items-center justify-center sm:flex ">
                <LazyLoadImage
                  src={melonatower}
                  alt="Melona Tower"
                  className="hidden md:block h-full max-h-[130vh] max-w-full lg:block rounded-2xl object-cover object-center"
                />
              </div>
            </div>
          </div>
        </div>
      </LazyLoadComponent>

      <LazyLoadComponent>
        <div className="z-10 px-6 py-10 xl:px-20 xl:mx-auto xl:container">
          <h1 className="mb-12 text-2xl font-semibold leading-tight text-center text-gray-800 md:text-4xl xl:text-6xl sm:mb-0">
            Melona Remains a Global Best Seller{" "}
            <br className="hidden md:block" />
            Throughout its <span className="text-red-600">30-Year History</span>
          </h1>

          <div className="relative items-center justify-center mt-4 md:mt-14 sm:flex">
            <LazyLoadImage
              src="https://i.ibb.co/KjrPCyW/map.png"
              alt="map"
              className="hidden object-cover w-full xl:h-full h-96 sm:block"
            />
            <LazyLoadImage
              src="https://i.ibb.co/SXKj9Mf/map-bg.png"
              alt="map"
              className="absolute z-0 block object-cover w-full -mt-10 sm:hidden h-96"
            />

            <div className="relative left-0 w-full p-4 mt-4 bg-white shadow-lg xl:p-6 sm:w-auto sm:absolute z-3 xl:ml-56 sm:ml-12 xl:-mt-40 sm:-mt-12">
              <p className="text-3xl font-semibold text-gray-800">30+</p>
              <p className="mt-2 text-base leading-4 text-gray-600 xl:mt-4">
                Countries Import Melona
              </p>
            </div>

            <div className="relative w-full p-4 mt-4 bg-white shadow-lg xl:p-6 sm:w-auto sm:absolute z-3 sm:mt-0 xl:mt-80 xl:-ml-0 sm:-ml-12">
              <p className="text-3xl font-semibold text-gray-800">280M+</p>
              <p className="mt-2 text-base leading-4 text-gray-600 xl:mt-4">
                Bars Sold Yearly
              </p>
            </div>

            <div className="relative right-0 w-full p-4 mt-4 bg-white shadow-lg xl:p-6 sm:w-auto sm:absolute z-3 md:mt-0 sm:-mt-5 xl:mr-56 sm:mr-24">
              <p className="text-3xl font-semibold text-gray-800">$80 Mil+</p>
              <p className="mt-2 text-base leading-4 text-gray-600 xl:mt-4">
                Annual Sales (CAD)
              </p>
            </div>
          </div>
        </div>
      </LazyLoadComponent>
    </React.Fragment>
  );
}

export default OurStory;
