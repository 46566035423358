import React from "react";
import GreenTeaSamanco from "../../assets/SamancoProductPage/NutritionPage/Samanco Green Tea.png";

const GreenTeaSamancoNutrition = () => {
  return (
    <div className="flex flex-col items-center justify-center px-4 pb-10 mb-10 sm:px-8 md:px-16 z-3">
      <div className="w-full max-w-screen-xl mx-auto rounded-lg">
        <div className="flex flex-col items-center justify-center md:flex-row">
          <img
            src={GreenTeaSamanco}
            alt="Food Product"
            className="object-cover w-64 h-auto mb-4 sm:w-52 md:w-64 lg:w-80 md:mb-0 md:mr-8"
          />
          <div className="mb-4">
            <h2 className="text-xl font-semibold text-black">DESCRIPTION</h2>
            <div className="h-1 my-2 bg-green-700"></div>
            <p className="text-lg text-black">
              Elevate your taste experience with Samanco Green Tea with Red Bean Filling, a fish-shaped ice waffle sandwich that's a true culinary masterpiece. Immerse yourself in the serene essence of green tea as you savor the crispy exterior, leading to the delightful surprise of red bean nestled within. The green tea filling and red bean create a moment of pure delight inspired by East Asian tradition.
            </p>
          </div>
        </div>
        <div className="mb-4">
          <h2 className="text-xl font-semibold text-black">INGREDIENTS</h2>
          <div className="h-1 my-2 bg-green-700"></div>
          <p className="text-lg text-black">
          Water, Sugars (sweetened red bean syrup, sugar, corn syrup, maltose syrup, sweetened red bean, high fructose corn syrup, lactose), Biscuit (wheat flour, corn starch, palm oil, whey, baking powder, hydrogenated coconut oil, sodium bicarbonate, milk cream, calcium hydroxide, carotene, sodium caseinate, calcium phosphate, ammonium bicarbonate, soy lecithin), Coconut oil, Skim milk powder, Whey powder, Red bean, Red bean paste, Green tea powder, Mono and diglycerides, Artificial flavors, Locust bean gum, Salt, Glutinous rice flour, Cellulose gum, Guar gum, Cinnamon powder, Carrageenan, Tartrazine, Brilliant blue FCF
            <br />
            <br />
            <span className="font-bold">Contains:</span> Wheat(Gluten), Soy, Milk
          </p>
        </div>
        <div className="mb-4 md:hidden">
          <h2 className="text-xl font-semibold text-black">NUTRITIONAL</h2>
          <div className="h-1 my-2 bg-green-700"></div>
          <div className="grid grid-cols-2 gap-2">
            <p className="font-semibold text-black">Serving Size</p>
            <p className="text-right text-black">1 Sandwich (150ml)</p>
            <p className="font-semibold text-black">Calories</p>
            <p className="text-right text-black">220</p>
            <p className="font-semibold text-black">Total Fat</p>
            <p className="text-right text-black">7 g</p>
            <p className="font-semibold text-black">Saturated Fat</p>
            <p className="text-right text-black">6 g</p>
            <p className="font-semibold text-black">Trans Fat</p>
            <p className="text-right text-black">0 g</p>
            <p className="font-semibold text-black">Cholesterol</p>
            <p className="text-right text-black">0 mg</p>
            <p className="font-semibold text-black">Sodium</p>
            <p className="text-right text-black">80 mg</p>
            <p className="font-semibold text-black">Total Carbohydrate</p>
            <p className="text-right text-black">38 g</p>
            <p className="font-semibold text-black">Sugars</p>
            <p className="text-right text-black">24 g</p>
            <p className="font-semibold text-black">Protein</p>
            <p className="text-right text-black">3 g</p>
            <p className="font-semibold text-black">Vitamin D</p>
            <p className="text-right text-black">0 g</p>
            <p className="font-semibold text-black">Potassium</p>
            <p className="text-right text-black">100 mg</p>
            <p className="font-semibold text-black">Calcium</p>
            <p className="text-right text-black">50 mg</p>
            <p className="font-semibold text-black">Iron</p>
            <p className="text-right text-black">0.2 mg</p>
          </div>
        </div>

        <div className="hidden md:block">
  <h2 className="text-xl font-semibold text-black">NUTRITIONAL</h2>
  <div className="h-1 my-2 bg-green-700"></div>
  <div className="grid grid-cols-4 gap-2">
    <div>
      <div className="font-semibold text-black">Serving Size</div>
      <div className="font-semibold text-black">Calories</div>
      <div className="font-semibold text-black">Total Fat</div>
      <div className="font-semibold text-black">Saturated Fat</div>
      <div className="font-semibold text-black">Trans Fat</div>
      <div className="font-semibold text-black">Cholesterol</div>
      <div className="font-semibold text-black">Sodium</div>
    </div>
    <div className="md:mr-5 lg:mr-20">
      <div className="text-right text-black">1 Sandwich (150ml)</div>
      <div className="text-right text-black">220</div>
      <div className="text-right text-black">7 g</div>
      <div className="text-right text-black">6 g</div>
      <div className="text-right text-black">0 g</div>
      <div className="text-right text-black">0 mg</div>
      <div className="text-right text-black">80 mg</div>
    </div>
    <div className="md:ml-5 lg:mr-20">
      <div className="font-semibold text-black">Total Carbohydrate</div>
      <div className="font-semibold text-black">Sugars</div>
      <div className="font-semibold text-black">Protein</div>
      <div className="font-semibold text-black">Vitamin D</div>
      <div className="font-semibold text-black">Potassium</div>
      <div className="font-semibold text-black">Calcium</div>
      <div className="font-semibold text-black">Iron</div>
    </div>
    <div>
      <div className="text-right text-black">38 g</div>
      <div className="text-right text-black">24 g</div>
      <div className="text-right text-black">3 g</div>
      <div className="text-right text-black">0 g</div>
      <div className="text-right text-black">100 mg</div>
      <div className="text-right text-black">50 mg</div>
      <div className="text-right text-black">0.2 mg</div>
    </div>
  </div>
</div>



      </div>
    </div>
  );
};

export default GreenTeaSamancoNutrition;
