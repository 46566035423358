import React from "react";
import ChocolateSamanco from "../../assets/SamancoProductPage/NutritionPage/Samanco Choco.png";

const ChocolateSamancoNutrition = () => {
  return (
    <div className="flex flex-col items-center justify-center px-4 pb-10 mb-10 sm:px-8 md:px-16 z-3">
      <div className="w-full max-w-screen-xl mx-auto rounded-lg">
        <div className="flex flex-col items-center justify-center md:flex-row">
          <img
            src={ChocolateSamanco}
            alt="Food Product"
            className="object-cover w-64 h-auto mb-4 sm:w-52 md:w-64 lg:w-80 md:mb-0 md:mr-8"
          />
          <div className="mb-4">
            <h2 className="text-xl font-semibold text-black">DESCRIPTION</h2>
            <div className="h-1 my-2 bg-amber-700"></div>
            <p className="text-lg text-black">
              Unwrap a moment of pure indulgence with Samanco Chocolate—a
              fish-shaped ice waffle sandwich that's a chocolate lover's
              dream. The crispy waffle exterior gives way to a luxurious
              chocolatey filling that's rich in every bite. Whether you're
              seeking comfort or a sweet treat, Samanco Chocolate is here to
              make every bite memorable.
            </p>
          </div>
        </div>
        <div className="mb-4">
          <h2 className="text-xl font-semibold text-black">INGREDIENTS</h2>
          <div className="h-1 my-2 bg-amber-700"></div>
          <p className="text-lg text-black">
          Water, Sugar (choco syrup, sugar, high fructose corn syrup, corn syrup, lactose), Biscuit (wheat flour, corn starch, palm oil, whey, baking powder, hydrogenated coconut oil, sodium bicarbonate, milk cream, calcium hydroxide, carotene, sodium caseinate, calcium phosphate, ammonium bicarbonate, soy lecithin), Coconut oil, Skim milk powder, Dark chocolate, Whey powder, Cocoa powder, Mono-and-diglycerides, Artificial flavors, Carrageenan, Cellulose gum, Salt, Guar gum, Polysorbate 80, Locust bean gum, Tartrazine, Sunset yellow FCF
            <br />
            <br />
            <span className="font-bold">Contains:</span> Wheat(Gluten), Soy, Milk
          </p>
        </div>
        <div className="mb-4 md:hidden">
          <h2 className="text-xl font-semibold text-black">NUTRITIONAL</h2>
          <div className="h-1 my-2 bg-amber-700"></div>
          <div className="grid grid-cols-2 gap-2">
            <div className="font-semibold text-black">Serving Size</div>
            <div className="text-right text-black">1 Sandwich (150ml)</div>
            <div className="font-semibold text-black">Calories</div>
            <div className="text-right text-black">210</div>
            <div className="font-semibold text-black">Total Fat</div>
            <div className="text-right text-black">8 g</div>
            <div className="font-semibold text-black">Saturated Fat</div>
            <div className="text-right text-black">6 g</div>
            <div className="font-semibold text-black">Trans Fat</div>
            <div className="text-right text-black">0 g</div>
            <div className="font-semibold text-black">Cholesterol</div>
            <div className="text-right text-black">0 mg</div>
            <div className="font-semibold text-black">Sodium</div>
            <div className="text-right text-black">65 mg</div>
            <div className="font-semibold text-black">Total Carbohydrate</div>
            <div className="text-right text-black">31 g</div>
            <div className="font-semibold text-black">Sugars</div>
            <div className="text-right text-black">20 g</div>
            <div className="font-semibold text-black">Protein</div>
            <div className="text-right text-black">3 g</div>
            <div className="font-semibold text-black">Vitamin D</div>
            <div className="text-right text-black">0 g</div>
            <div className="font-semibold text-black">Potassium</div>
            <div className="text-right text-black">125 mg</div>
            <div className="font-semibold text-black">Calcium</div>
            <div className="text-right text-black">50 mg</div>
            <div className="font-semibold text-black">Iron</div>
            <div className="text-right text-black">0.1 mg</div>
          </div>
        </div>
        <div className="hidden md:block">
  <h2 className="text-xl font-semibold text-black">NUTRITIONAL</h2>
  <div className="h-1 my-2 bg-amber-700"></div>
  <div className="grid grid-cols-4 gap-2">
    <div>
      <div className="font-semibold text-black">Serving Size</div>
      <div className="font-semibold text-black">Calories</div>
      <div className="font-semibold text-black">Total Fat</div>
      <div className="font-semibold text-black">Saturated Fat</div>
      <div className="font-semibold text-black">Trans Fat</div>
      <div className="font-semibold text-black">Cholesterol</div>
      <div className="font-semibold text-black">Sodium</div>
    </div>
    <div className="md:mr-5 lg:mr-20">
      <div className="text-right text-black">1 Sandwich (150ml)</div>
      <div className="text-right text-black">210</div>
      <div className="text-right text-black">8 g</div>
      <div className="text-right text-black">6 g</div>
      <div className="text-right text-black">0 g</div>
      <div className="text-right text-black">0 mg</div>
      <div className="text-right text-black">65 mg</div>
    </div>
    <div className="md:ml-5 lg:mr-20">
      <div className="font-semibold text-black">Total Carbohydrate</div>
      <div className="font-semibold text-black">Sugars</div>
      <div className="font-semibold text-black">Protein</div>
      <div className="font-semibold text-black">Vitamin D</div>
      <div className="font-semibold text-black">Potassium</div>
      <div className="font-semibold text-black">Calcium</div>
      <div className="font-semibold text-black">Iron</div>
    </div>
    <div>
      <div className="text-right text-black">31 g</div>
      <div className="text-right text-black">20 g</div>
      <div className="text-right text-black">3 g</div>
      <div className="text-right text-black">0 g</div>
      <div className="text-right text-black">125 mg</div>
      <div className="text-right text-black">50 mg</div>
      <div className="text-right text-black">0.1 mg</div>
    </div>
  </div>
</div>

      </div>
    </div>
  );
};

export default ChocolateSamancoNutrition;
