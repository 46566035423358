import React from "react";
import Westernlogo from "../assets/logo/western.png";
import SFU from "../assets/logo/SFU.png";
import UBC from "../assets/logo/UBC.png";
import { Link } from "react-router-dom";
import Binggraelogo from "../assets/logo/binggraelogo.png";

function SponsorshipPopup() {
  return (
    <div className="flex flex-col justify-center items-center mt-10 px-4">
      <div className="w-full max-w-6xl rounded-2xl shadow-lg ring-8 ring-offset-3 p-6 lg:p-8 bg-white ring-red-500">
        <div className="flex flex-col lg:flex-row items-center lg:justify-between space-y-4 lg:space-y-0 lg:space-x-8">
          <div className="flex flex-col justify-center items-center">
            <div className="flex flex-col items-center">
              <img
                src={Binggraelogo}
                alt="logo"
                className="w-auto h-16 lg:h-24"
              />
              <p className="flex justify-center items-center mt-4 text-xl lg:text-2xl font-bold">
                BUILDING COMMUNITY
              </p>
            </div>
          </div>
          <div className="space-y-2 lg:w-2/3 text-center mt-0">
            <p className="text-lg lg:text-xl text-black font-sans mx-2">
              At Binggrae, our mission is to build vibrant communities and
              empower youth by providing top-quality products. We believe in
              creating delicious and refreshing treats that not only satisfy
              taste buds but also contribute to the growth and development of
              individuals and society. Through our commitment to excellence, we
              aim to bring joy and happiness to people's lives while making a
              positive impact on the world. Together with our sponsors, we
              strive to make a difference and foster a future full of
              possibilities for the next generation.
            </p>
            <Link to="/Contact">
              <p className="text-black font-semibold underline mt-3">
                Get Sponsored
              </p>
            </Link>
          </div>
        </div>
        <div className="h-5/6 flex justify-evenly items-center mt-6">
          <img
            className="h-full w-1/4"
            src={Westernlogo}
            alt="Sponsor Logo 1"
          />
          <img className="h-full w-1/4" src={SFU} alt="Sponsor Logo 2" />
          <img className="h-full w-1/4" src={UBC} alt="Sponsor Logo 3" />
        </div>
      </div>
    </div>
  );
}
export default SponsorshipPopup;
