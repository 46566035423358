import React, { useState } from "react";

const GalleryImageSlider = ({ images, headerImage }) => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  const navigate = (direction) => {
    if (direction === "prev") {
      setCurrentImageIndex(
        (currentImageIndex - 1 + images.length) % images.length
      );
    } else {
      setCurrentImageIndex((currentImageIndex + 1) % images.length);
    }
  };

  return (
    <div className="flex flex-col items-center justify-center -z-4">
      <img className="h-auto w-auto max-h-[50vh] max-w-[50vh]" src={headerImage}/>
      <div className="relative flex items-center justify-center w-full -z-4 sm:w-3/4">
        <img
          src={images[currentImageIndex]}
          alt="Slider"
          className="w-full h-[50vh] sm:h-[70vh] object-contain  transition-transform duration-300 transform hover:scale-110"
        />
        <div className="absolute inset-0 flex items-center justify-between">
          <button
            onClick={() => navigate("prev")}
            className="p-2 text-white bg-gray-800 bg-opacity-50 rounded-full hover:bg-opacity-75 focus:outline-none animate-bounce"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="w-6 h-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M15 19l-7-7 7-7"
              />
            </svg>
          </button>
          <button
            onClick={() => navigate("next")}
            className="p-2 text-white bg-gray-800 bg-opacity-50 rounded-full hover:bg-opacity-75 focus:outline-none animate-bounce"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="w-6 h-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M9 5l7 7-7 7"
              />
            </svg>
          </button>
        </div>
      </div>
    </div>
  );
};

export default GalleryImageSlider;
