import React, { useState } from "react";
import Pangtoa from "../assets/PangToaProductPage/PangtoaProduct.jpg";
import PangtoaNutrition from "../components/nutritionalInfoPangtoa/PangtoaNutrition";
import {
  LazyLoadComponent,
  LazyLoadImage,
} from "react-lazy-load-image-component";

function PangtoaProductPage() {
  const PangtoaFlavours = [
    {
      image: Pangtoa,
      nutrition: <PangtoaNutrition />,
      backgroundColour: "#fef9c3", // Add background color for Pangtoa flavor
    },
  ];

  const [currentFlavorIndex] = useState(0);

  const currentFlavor = PangtoaFlavours[currentFlavorIndex];

  return (
    <React.Fragment>
       <div
        className="pt-20"
        style={{ backgroundColor: currentFlavor.backgroundColour }}
      >
        <div className="z-3">
          <LazyLoadImage
            src={currentFlavor.image}
            alt="Pangtoa Product"
            className="w-full h-auto rounded-lg md:rounded-xl"
          />
        </div>
        <LazyLoadComponent>
          <div className="mb-8 md:mb-16 lg:mb-20 -z-4">
            {currentFlavor.nutrition}
          </div>
        </LazyLoadComponent>
      </div>
    </React.Fragment>
  );
}

export default PangtoaProductPage;
