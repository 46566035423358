import React, { useState } from "react";
import MelonaMelona from "../assets/products/MelonMelona/MelonMelona3.png";
import BananaMelona from "../assets/products/BananaMelona/BananaMelona5.png";
import CoconutMelona from "../assets/products/CoconutMelona/CoconutMelona4.png";
import MangoMelona from "../assets/products/MangoMelona/MangoMelona3.png";
import StrawberryMelona from "../assets/products/StrawberryMelona/StrawberryMelona3.png";
import UbeMelona from "../assets/products/UbeMelona/UbeMelona3.png";
import MelonIcon from "../assets/MelonaIcons/melonIcon.png";
import BananaIcon from "../assets/MelonaIcons/bananaIcon.png";
import CoconutIcon from "../assets/MelonaIcons/coconutIcon.png";
import MangoIcon from "../assets/MelonaIcons/mangoIcon.png";
import StrawberryIcon from "../assets/MelonaIcons/strawberryIcon.png";
import UbeIcon from "../assets/MelonaIcons/ubeIcon.png";
import MelonNutritionalInformation from "../components/nutritionalInfoMelonaBars/MelonMelonaNutrition.jsx.jsx";
import MangoNutritionalInformation from "../components/nutritionalInfoMelonaBars/MangoMelonaNutrition.jsx";
import StrawberryNutritionalInformation from "../components/nutritionalInfoMelonaBars/StrawberryMelonaNutrition.jsx";
import UbeNutritionalInformation from "../components/nutritionalInfoMelonaBars/UbeMelonaNutrition.jsx";
import BananaMelonaNutrition from "../components/nutritionalInfoMelonaBars/BananaMelonaNutrition";
import CoconutMelonaNutrition from "../components/nutritionalInfoMelonaBars/CoconutMelonaNutrition";
import {
  LazyLoadComponent,
  LazyLoadImage,
} from "react-lazy-load-image-component";

function MelonaProducts() {
  const melonaFlavors = [
    {
      image: MelonaMelona,
      icon: MelonIcon,
      nutrition: <MelonNutritionalInformation />,
      backgroundColour: "#dcfce7", // Add background color for Melon flavor
    },
    {
      image: BananaMelona,
      icon: BananaIcon,
      nutrition: <BananaMelonaNutrition />,
      backgroundColour: "#fef3c7", // Add background color for Banana flavor
    },
    {
      image: CoconutMelona,
      icon: CoconutIcon,
      nutrition: <CoconutMelonaNutrition />,
      backgroundColour: "#e0f2fe", // Add background color for Coconut flavor
    },
    {
      image: MangoMelona,
      icon: MangoIcon,
      nutrition: <MangoNutritionalInformation />,
      backgroundColour: "#fef3c7", // Add background color for Mango flavor
    },
    {
      image: StrawberryMelona,
      icon: StrawberryIcon,
      nutrition: <StrawberryNutritionalInformation />,
      backgroundColour: "#fee2e2", // Add background color for Strawberry flavor
    },
    {
      image: UbeMelona,
      icon: UbeIcon,
      nutrition: <UbeNutritionalInformation />,
      backgroundColour: "#f3e8ff", // Add background color for Ube flavor
    },
  ];

  const [currentFlavorIndex, setCurrentFlavorIndex] = useState(0);

  const currentFlavor = melonaFlavors[currentFlavorIndex];

  const firstRowIconsMobile = [MelonIcon, BananaIcon, CoconutIcon];
  const firstRowIconsDesktop = [
    MelonIcon,
    BananaIcon,
    CoconutIcon,
    MangoIcon,
    StrawberryIcon,
    UbeIcon,
  ];

  const secondRowIcons = [MangoIcon, StrawberryIcon, UbeIcon];

  return (
    <React.Fragment>
      <div
        className="pt-20"
        style={{ backgroundColor: currentFlavor.backgroundColour }}
      >
        <div className="z-3 relative">
          <LazyLoadImage
            src={currentFlavor.image}
            alt="Melona Product"
            className="w-400 h-auto rounded-lg md:rounded-xl" // Adjust the width as needed
          />

          <div className="flex justify-center mt-4">
            {/* Display the mobile icons only on mobile screens */}
            <div className="md:hidden">
              {firstRowIconsMobile.map((icon, index) => (
                <button
                  key={index}
                  className={`mx-2 ${
                    currentFlavorIndex === index
                      ? "transform scale-110"
                      : "transition-transform hover:scale-110"
                  }`}
                  onClick={() => setCurrentFlavorIndex(index)}
                >
                  <LazyLoadImage
                    src={icon}
                    alt={`Melona Flavor Icon ${index}`}
                    className="w-20 h-20 animate-bounce"
                  />
                </button>
              ))}
            </div>

            {/* Display all icons for desktop and iPad screens */}
            <div className="hidden md:flex">
              {firstRowIconsDesktop.map((icon, index) => (
                <button
                  key={index}
                  className={`mx-2 ${
                    currentFlavorIndex === index
                      ? "transform scale-110"
                      : "transition-transform hover:scale-110"
                  }`}
                  onClick={() => setCurrentFlavorIndex(index)}
                >
                  <LazyLoadImage
                    src={icon}
                    alt={`Melona Flavor Icon ${index}`}
                    className="w-20 h-20 animate-bounce"
                  />
                </button>
              ))}
            </div>
          </div>

          <div className="flex justify-center mt-4 md:hidden">
            {secondRowIcons.map((icon, index) => (
              <button
                key={index}
                className={`mx-2 ${
                  currentFlavorIndex === index + firstRowIconsMobile.length
                    ? "transform scale-110"
                    : "transition-transform hover:scale-110"
                }`}
                onClick={() =>
                  setCurrentFlavorIndex(index + firstRowIconsMobile.length)
                }
              >
                <LazyLoadImage
                  src={icon}
                  alt={`Melona Flavor Icon ${index}`}
                  className="w-20 h-20 animate-bounce"
                />
              </button>
            ))}
          </div>
        </div>

        <LazyLoadComponent>
          <div className="z-3">{currentFlavor.nutrition}</div>
        </LazyLoadComponent>
      </div>
    </React.Fragment>
  );
}

export default MelonaProducts;
