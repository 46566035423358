import React from "react";
import Pangtoa from "../../assets/PangToaProductPage/Pangtoa.png";

const PangtoaNutrition = () => {
  return (
    <div className="flex flex-col items-center justify-center px-4 mb-10 sm:px-8 md:px-16 z-3">
      <div className="w-full max-w-screen-xl p-6 mx-auto rounded-lg md:p-12 lg:p-20">
        <div className="flex flex-col items-center justify-center md:flex-row">
          <img
            src={Pangtoa}
            alt="Food Product"
            className="object-cover w-64 h-auto mb-4 sm:w-52 md:w-64 lg:w-80 md:mb-0 md:mr-8"
          />
          <div className="mb-4">
            <h2 className="text-xl font-semibold text-black">DESCRIPTION</h2>
            <div className="h-1 my-2 bg-amber-700"></div>
            <p className="text-lg text-black">
              Elevate your snacking experience with our Pangtoa in the delectable Cookies and Cream flavor. Indulge in the perfect harmony of rich chocolate cookies and velvety cream where you'll discover the exquisite balance of crunch and smoothness. Whether you're seeking a delightful treat or a blissful escape, Pangtoa Cookies and Cream is here to elevate your cravings and redefine your snack time.
            </p>
          </div>
        </div>
        <div className="mb-4">
          <h2 className="text-xl font-semibold text-black">INGREDIENTS</h2>
          <div className="h-1 my-2 bg-amber-700"></div>
          <p className="text-lg text-black">
            Water, Sponge Cake (Wheat Flour, Sugar, Egg, Corn Syrup, Glucose, Baking Powder, High Fructose Corn Syrup, Mono- and Diglycerides (471), Propylene Glycol (1520), Sucrose Esters of Fatty Acids (473), Polysorbate 60 (435), Ethyl Alcohol, Sorbitan Monostearate (491), Propylene Glycol Mono Fatty Acid Esters (477), Salt, Artificial Flavor, Xanthan Gum (415)), Sugar, Cookies (Sugar, Wheat Flour, Vegetable Oils (Palm Stearin, Palm Oil, Coconut Oil and Sunflower Oil), Cacao Powder, Corn Syrup, Corn Starch, Sodium Bicarbonate (500), Ammonium Bicarbonate (503), Salt, Artificial Flavors, Soy Lecithin (322)), Corn Syrup, Skim Milk Powder, Dairy Spread, Coconut Oil, Whey Powder, Mono- and Diglycerides (471), Coffee Powder, Artificial Flavors, Salt, Cellulose Gum (466), Locust Bean Gum (410), Guar Gum (412), Carrageenan (407)
          </p>
          <br />
          <br />
          <span className="font-bold">Contains:</span> Wheat, Soybean, Egg and Milk
        </div>
        <div className="mb-4 md:hidden">
          <h2 className="text-xl font-semibold text-black">NUTRITIONAL</h2>
          <div className="h-1 my-2 bg-amber-700"></div>
          <div className="grid grid-cols-2 gap-1">
            <p className="font-semibold text-black">Serving Size</p>
            <p className="text-right text-black">1 Sandwich (180ml)</p>
            <p className="font-semibold text-black">Calories</p>
            <p className="text-right text-black">200</p>
            <p className="font-semibold text-black">Total Fat</p>
            <p className="text-right text-black">6 g</p>
            <p className="font-semibold text-black">Saturated Fat</p>
            <p className="text-right text-black">5 g</p>
            <p className="font-semibold text-black">Trans Fat</p>
            <p className="text-right text-black">0g</p>
            <p className="font-semibold text-black">Cholesterol</p>
            <p className="text-right text-black">30 mg</p>
            <p className="font-semibold text-black">Sodium</p>
            <p className="text-right text-black">150 mg</p>
            <p className="font-semibold text-black">Total Carbohydrate</p>
            <p className="text-right text-black">33 g</p>
            <p className="font-semibold text-black">Sugars</p>
            <p className="text-right text-black">21 g</p>
            <p className="font-semibold text-black">Protein</p>
            <p className="text-right text-black">30 g</p>
            <p className="font-semibold text-black">Vitamin D</p>
            <p className="text-right text-black">0 g</p>
            <p className="font-semibold text-black">Potassium</p>
            <p className="text-right text-black">150mg</p>
            <p className="font-semibold text-black">Calcium</p>
            <p className="text-right text-black">130mg</p>
            <p className="font-semibold text-black">Iron</p>
            <p className="text-right text-black">0.4mg</p>
          </div>
        </div>

        <div className="hidden md:block">
          <h2 className="text-xl font-semibold text-black">NUTRITIONAL</h2>
          <div className="h-1 my-2 bg-amber-700"></div>
          <div className="grid grid-cols-4 gap-1">
            <div>
              <p className="font-semibold text-black">Serving Size</p>
              <p className="font-semibold text-black">Calories</p>
              <p className="font-semibold text-black">Total Fat</p>
              <p className="font-semibold text-black">Saturated Fat</p>
              <p className="font-semibold text-black">Trans Fat</p>
              <p className="font-semibold text-black">Cholesterol</p>
              <p className="font-semibold text-black">Sodium</p>
            </div>
            <div className="md:mr-2 lg:mr-20">
              <p className="text-right text-black">1 Sandwich (180ml)</p>
              <p className="text-right text-black">200</p>
              <p className="text-right text-black">6 g</p>
              <p className="text-right text-black">5 g</p>
              <p className="text-right text-black">0g</p>
              <p className="text-right text-black">30 mg</p>
              <p className="text-right text-black">150 mg</p>
            </div>
            <div className="md:ml-2 lg:mr-20">
              <p className="font-semibold text-black">Total Carbohydrate</p>
              <p className="font-semibold text-black">Sugars</p>
              <p className="font-semibold text-black">Protein</p>
              <p className="font-semibold text-black">Vitamin D</p>
              <p className="font-semibold text-black">Potassium</p>
              <p className="font-semibold text-black">Calcium</p>
              <p className="font-semibold text-black">Iron</p>
            </div>
            <div>
              <p className="text-right text-black">33 g</p>
              <p className="text-right text-black">21 g</p>
              <p className="text-right text-black">30 g</p>
              <p className="text-right text-black">0 g</p>
              <p className="text-right text-black">150 mg</p>
              <p className="text-right text-black">130 mg</p>
              <p className="text-right text-black">0.4 mg</p>
            </div>
          </div>
        </div>

      </div>
    </div>
  );
};

export default PangtoaNutrition;
