import React from "react";
import KitsHeader from "../assets/GalleryImages/kits/melonaxkhatsalano.png";
import WMAPBSNHeader from "../assets/GalleryImages/PBSN/melonaxwmaxpbsn.png";
import Kits1 from "../assets/GalleryImages/kits/kits1-min.jpg";
import Kits2 from "../assets/GalleryImages/kits/kits2-min.jpg";
import Kits3 from "../assets/GalleryImages/kits/kits3-min.jpg";
import Kits4 from "../assets/GalleryImages/kits/kits4-min.jpg";
import Kits5 from "../assets/GalleryImages/kits/kits5-min.jpg";
import Kits6 from "../assets/GalleryImages/kits/kits6-min.jpg";
import Kits7 from "../assets/GalleryImages/kits/kits7-min.jpg";
import Kits8 from "../assets/GalleryImages/kits/kits8-min.jpg";
import Kits9 from "../assets/GalleryImages/kits/kits9-min.jpg";
import Kits10 from "../assets/GalleryImages/kits/kits10-min.jpg";
import Kits11 from "../assets/GalleryImages/kits/kits11-min.jpg";
import PBSN1 from "../assets/GalleryImages/PBSN/PBSN1-min.jpg";
import PBSN2 from "../assets/GalleryImages/PBSN/PBSN2-min.jpg";
import PBSN3 from "../assets/GalleryImages/PBSN/PBSN3-min.jpg";
import PBSN4 from "../assets/GalleryImages/PBSN/PBSN4-min.jpg";
import GalleryImageSlider from "../components/GalleryImageSlider";
import {
  LazyLoadImage,
  LazyLoadComponent,
} from "react-lazy-load-image-component";

function PhotoGallery() {
  const kitsimages = [
    Kits1,
    Kits2,
    Kits3,
    Kits4,
    Kits5,
    Kits6,
    Kits7,
    Kits8,
    Kits9,
    Kits10,
    Kits11,
  ];

  const PBSNimages = [PBSN1, PBSN2, PBSN3, PBSN4];

  return (
    <React.Fragment>
      <div className="mt-20 -z-4">
        <div className="">
          <GalleryImageSlider images={kitsimages} headerImage={KitsHeader} />
        </div>

        <LazyLoadComponent>
          <div className="mb-10 mt-10">
            <GalleryImageSlider
              images={PBSNimages}
              headerImage={WMAPBSNHeader}
            />
          </div>
        </LazyLoadComponent>
      </div>
    </React.Fragment>
  );
}

export default PhotoGallery;
