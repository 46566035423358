import React, { useState } from "react";
import CostcoMelona from "../assets/products/costco_melona.jpg";
import MelonNutritionalInformation from "../components/costconutritionalInfo/CostcoMelonMelona.jsx";
import MangoNutritionalInformation from "../components/costconutritionalInfo/CostcoMangoMelona.jsx";
import CoconutMelonaNutrition from "../components/costconutritionalInfo/CostcoCoconutMelona.jsx";
import MangoIcon from "../assets/MelonaIcons/mangoIcon.png";
import CoconutIcon from "../assets/MelonaIcons/coconutIcon.png";
import MelonIcon from "../assets/MelonaIcons/melonIcon.png";
import CostcoLogo from "../assets/stores/costco.png";
import {
  LazyLoadComponent,
  LazyLoadImage,
} from "react-lazy-load-image-component";

function CostcoMelonaProductPage() {
  const melonaFlavors = [
    {
      icon: MelonIcon,
      nutrition: <MelonNutritionalInformation />,
      backgroundColour: "#dcfce7", // Add background color for Melon flavor
    },
    {
      icon: CoconutIcon,
      nutrition: <CoconutMelonaNutrition />,
      backgroundColour: "#e0f2fe", // Add background color for Coconut flavor
    },
    {
      icon: MangoIcon,
      nutrition: <MangoNutritionalInformation />,
      backgroundColour: "#fef3c7", // Add background color for Mango flavor
    },
  ];

  const [currentFlavorIndex, setCurrentFlavorIndex] = useState(1);

  const currentFlavor = melonaFlavors[currentFlavorIndex];

  const firstRowIcons = [MelonIcon, CoconutIcon, MangoIcon];

  return (
    <div
      className="pt-20 pb-10 mb-10"
      style={{ backgroundColor: currentFlavor.backgroundColour }}
    >
      <div className="z-3">
        <LazyLoadImage
          src={CostcoMelona}
          alt="Melona Product"
          className="w-full h-auto rounded-lg md:rounded-xl"
        />

        <LazyLoadComponent>
          <div className="flex justify-center items-center mt-4">
            {firstRowIcons.map((icon, index) => (
              <button
                key={index}
                className={`mx-2 ${
                  currentFlavorIndex === index
                    ? "transform scale-110"
                    : "transition-transform hover:scale-110"
                }`}
                onClick={() => setCurrentFlavorIndex(index)}
              >
                <LazyLoadImage
                  src={icon}
                  alt={`Melona Flavor Icon ${index}`}
                  className="w-20 h-20 animate-bounce"
                />
              </button>
            ))}
          </div>
        </LazyLoadComponent>
      </div>

      <LazyLoadComponent>
        <div className="mb-5">{currentFlavor.nutrition}</div>
        <div className="mb-4 md:mb-10 lg:mb-8 text-center">
          <LazyLoadImage
            src={CostcoLogo}
            alt="Exclusively at Costco"
            className="mx-auto mb-10 h-24 md:h-24 lg:h-32"
          />
        </div>
      </LazyLoadComponent>
    </div>
  );
}

export default CostcoMelonaProductPage;
