import React from "react";
import { createRoot } from "react-dom/client";
import Header from "./components/header";
import Footer from "./components/footer";
import Contact from "./pages/Contact";
import Landing from "../src/pages/Landing";
import OurStory from "./pages/OurStory";
import MelonaProducts from "./pages/MelonaBarsProductPage";
import BinggraeStory from "./pages/BinggraeStory";
import PhotoGallery from "./pages/PhotoGallery";
import CostcoMelonaProduct from "../src/pages/CostcoMelonaProductPage";
import SamancoProduct from "../src/pages/SamancoProductPage";
import MelonaMilkProduct from "../src/pages/MelonaMilkProductPage";
import BBbigProduct from "../src/pages/BBbigProductPage";
import PowerCapProduct from "./pages/PowerCapProductPage";
import PangtoaProduct from "./pages/PangtaoProductPage";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter, Route, Routes } from "react-router-dom";

const rootElement = document.getElementById("root");
const root = createRoot(rootElement);

root.render(
  <BrowserRouter>
    <Header />
    <Routes>
      <Route path="/" element={<Landing />} />
      <Route path="/MelonaDeployTest" element={<Landing />} />
      <Route path="/MelonaDeployTest/*" element={<Landing />} />
      <Route path="/Contact" element={<Contact />} />
      <Route path="/MelonaStory" element={<OurStory />} />
      <Route path="/MelonaBarsProducts" element={<MelonaProducts />} />
      <Route path="/CostcoMelona" element={<CostcoMelonaProduct />} />
      <Route path="/MelonaMilk" element={<MelonaMilkProduct />} />
      <Route path="/Samanco" element={<SamancoProduct />} />
      <Route path="/BBbig" element={<BBbigProduct />} />
      <Route path="/PowerCap" element={<PowerCapProduct />} />
      <Route path="/Pangtoa" element={<PangtoaProduct />} />
      <Route path="/BingStory" element={<BinggraeStory />} />
      <Route path="/PhotoGallery" element={<PhotoGallery />} />
    </Routes>
    <Footer />
  </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
